<template>
    <div
        :class="input.class"
    >
        {{ input.value }}
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'e-label',
    props: {
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
})

</script>

<style lang="less">
.warning {
    color: orange;
}
</style>