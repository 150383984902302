<template>
    <div>
        <input
            id="timezone"
            type="hidden"
            name="timezone"
            value="+01:00"
        >
        <v-text-field
            v-model="selected[input.scope]"
            type="datetime-local"
            :hide-details="hideDetails"
            variant="outlined"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EDatetime',
    props: {
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
    created() {
        if (!this.input.setNowOnLoad) {
            return;
        }
        
        if (!this.selected.visit_started) {
            const tzoffset = (new Date()).getTimezoneOffset() * 60000;
            const localISOTime = (new Date(Date.now() - tzoffset)).toISOString();
            this.$emit('setScope', this.input.scope, localISOTime.slice(0, 16))
        }
    }
})

</script>
