<template>
    <Transition>
        <v-container :class="{'green-bg': kkInstance}">
            <v-col cols="12">
                <div
                    v-if="kkInstance"
                    class="page-title d-flex justify-center mb-4"
                >
                    <h1>
                        Välj besök
                    </h1>
                </div>

                <v-col 
                    v-if="event && !kkInstance" 
                    align="center"
                    class="info-col sticky-header"
                >
                    <h1>{{ getEventTitleInfo(event.title).eventType }}</h1>

                    <span class="category-span">PROV-ID</span>
                    <h4>#{{ getEventId(event) }}</h4>

                    <span class="category-span">PLATS</span>
                    <h4>{{ getEventTitleInfo(event.title).eventLocation }}</h4>

                    <span class="category-span">DATUM</span>
                    <h4
                        style="text-transform: capitalize;
"
                    >
                        {{ getEventDate(event.ts) }}
                    </h4>

                    <span class="category-span">KATEGORIER</span>
                    <h4>
                        {{ getEventCategoriesString(event.categories) }}
                    </h4>
                </v-col>
                
                <div class="mb-8">
                    <v-text-field
                        v-model="search"
                        placeholder="Sök"
                        variant="solo"
                        append-inner-icon="mdi-magnify"
                        class="rounded-md"
                    />
                
                    <div class="d-flex justify-end mt-n4 text-subtitle-2">
                        <strong
                            v-if="search !== ''"
                            class="mr-auto"
                        >
                            Sökträffar: {{ filteredRegistrations.length }}
                        </strong>
                        <strong>
                            Registrerade
                            <span v-if="kkInstance">
                                besök:
                            </span>
                            <span v-else>hundar:</span>
                            {{ registrations.registrations.length }}
                        </strong>
                    </div>
                </div>

                <v-list
                    class="pa-0"
                    style="background: none;"
                >
                    <v-list-item
                        v-for="(reg) in filteredRegistrations"
                        v-show="reg.visible"
                        :key="reg.id"
                        elevation="2"
                        class="rounded-md mb-2 px-4 py-2 bg-white"
                        @click="$router.push({
                            name: 'RegistrationView',
                            params: {
                                'regId': reg.id,
                            }
                        })"
                    >
                        <template #prepend>
                            <v-avatar
                                :color="avatarColors[reg.id]"
                            >
                                <span v-if="reg.start_order != null">{{ reg.start_order + 1 }}</span>
                            </v-avatar>
                        </template>
                        <v-list-item-title class="text-subtitle-1">
                            <strong>{{ reg.title ?? '' }}</strong>
                        </v-list-item-title>
                        <v-list-item-subtitle
                            v-if="reg.class"
                            class="text-subtitle-2"
                        >
                            Klass: {{ reg.class }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                            v-if="reg.breed"
                            class="text-subtitle-2"
                        >
                            Ras: {{ Array.isArray(reg.breed) ? reg.kennel.breed.join(', ') : reg.breed }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-subtitle-2">
                            {{ reg.ts ? registrationDate(reg) : '' }}
                        </v-list-item-subtitle>
                        <template #append>
                            <v-icon color="info">
                                mdi-arrow-right
                            </v-icon>
                        </template>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-container>
    </Transition>
</template> 

<script lang="ts">
import { defineComponent } from 'vue';
import { TypeRegistration, TypeEvent, TypeEventDateTs, useEventStore, useRegistrationsStore, useUserStore, useEvaluationsStore } from '@/stores/store';
import { getEventId, getEventTitleInfo, getEventDate, getEventCategoriesString } from '@/helpers/eventInfoHelper';

export default defineComponent({
    name: 'RegistrationList',
    data() {
        return {
            userStore: useUserStore().getUser(),
            events: useEventStore(),
            registrations: useRegistrationsStore(),
            search: '',
            event: {} as TypeEvent,
            avatarColors: {} as Record<string, string>
        }
    },
    computed: {
        filteredRegistrations() {
            return this.registrations.registrations
                .filter((reg) => reg.title.toLowerCase().match(this.search.toLowerCase()))
                .sort((a, b) => {
                    if (a.start_order === null && b.start_order !== null) {
                        return -1;
                    }
                    if (b.start_order === null && a.start_order !== null) {
                        return 1
                    }
                    if (a.start_order === null && b.start_order === null) {
                        return 0;
                    }

                    // @ts-ignore
                    return a.start_order > b.start_order ? 1 : -1;
                });
        },

        isOnline() {
            return window.navigator.onLine;
        },

        kkInstance() {
            return ['kk', 'kk_dev', 'kk_test', 'kk_local'].includes(this.userStore.getEndpoint);
        }
    },
    async mounted() {
        await this.events.init();
        this.event = this.getEvent();

        if (!this.registrations.registrations.length) {
            await this.registrations.init(this.event);
        }

        this.registrations.registrations.forEach((reg) => {
            this.avatarColors[reg.id] = 'surface-variant';
            this.updateAvatarColor(reg);
        });
    },
    methods: {
        async getAvatarColor(reg: TypeRegistration) {
            if (reg.sentTo) {
                return 'green';
            }
            const evaluationStarted = await this.evaluationStarted(reg);
            if (evaluationStarted) {
                return 'orange';
            }

            return 'surface-variant';
        },
        
        async updateAvatarColor(reg: TypeRegistration) {
            const color = await this.getAvatarColor(reg);
            this.avatarColors[reg.id] = color; // Directly assign the color
        },

        evaluationStarted(reg: TypeRegistration) {
            return useEvaluationsStore().evaluationStarted(reg.path);
        },

        registrationDate(reg: TypeRegistration) {
            const locale = 'sv-SE'
            const options = {
                timeStyle: 'short'
            } as Intl.DateTimeFormatOptions;

            const date = reg.ts.toDate()

            if (date.getHours() === 1 || date.getHours() === 2) {
                return 'Heldag';
            }

            return `Tid: ${date.toLocaleTimeString(locale, options)}`
        },

        getEvent() {
            return this.events.getEventByID(this.$route.params.eventId as string);
        },

        getEventId(event: TypeEvent) {
            return getEventId(event);
        },

        getEventTitleInfo(title: string) {
            return getEventTitleInfo(title);
        },

        getEventDate(ts: TypeEventDateTs) {
            return getEventDate(ts);
        },

        getEventCategoriesString(categories: Array<string>) {
            return getEventCategoriesString(categories);
        }
    }
})

</script>

<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 0 0 10px 10px white;
    z-index: 10;
    padding: 10px;
    margin-bottom: 15px;
}
</style>
