import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, {
    class: "pl-6 pt-6 pr-5",
    value: _ctx.modelValue,
    type: "date",
    error: _ctx.modelValue === '',
    "error-messages": _ctx.modelValue === '' ? 'Provdag måste väljas!' : undefined,
    variant: "outlined",
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
  }, null, 8, ["value", "error", "error-messages"]))
}