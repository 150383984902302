import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_textarea = _resolveComponent("v-textarea")!

  return (_ctx.input.max)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_textarea, {
          modelValue: _ctx.selected[_ctx.input.scope],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
          "hide-details": _ctx.hideDetails,
          outlined: "",
          placeholder: _ctx.input.value ?? `Kommentar`,
          onKeyup: _ctx.countdown
        }, {
          details: _withCtx(() => [
            _createElementVNode("p", {
              class: "text-right text-small",
              style: _normalizeStyle(_ctx.hasError ? 'color: red' : '')
            }, _toDisplayString(_ctx.remainingCount), 5)
          ]),
          _: 1
        }, 8, ["modelValue", "hide-details", "placeholder", "onKeyup"])
      ]))
    : (_openBlock(), _createBlock(_component_v_textarea, {
        key: 1,
        modelValue: _ctx.selected[_ctx.input.scope],
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
        "hide-details": _ctx.hideDetails,
        outlined: "",
        placeholder: _ctx.input.value ?? 'Kommentar'
      }, null, 8, ["modelValue", "hide-details", "placeholder"]))
}