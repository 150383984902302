import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createBlock(_component_v_col, { style: {"margin-bottom":"10px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { style: {"justify-content":"space-between"} }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.input.values, (v, idx) => {
            return (_openBlock(), _createElementBlock("h4", { key: idx }, _toDisplayString(v.name), 1))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}