<template>
    <v-app
        :full-height="true"
        :class="{'has-nav': showNav}"
    >
        <div v-if="showNav">
            <nav-bar />
        </div>

        <v-layout class="content">
            <router-view />
        </v-layout>
    </v-app>
</template>

<script lang="ts">
import NavBar from '@/components/NavBar.vue'

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'App',
    components: {
        NavBar,
    },
    computed: {
        showNav() {
            return this.$route.fullPath !== '/';
        },
    },
})
</script>

<style lang="less">
    @import './less/app.less';
</style>
