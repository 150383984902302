import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"padding":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.trackingState.finished)
      ? (_openBlock(), _createBlock(_component_v_btn, {
          key: 0,
          style: {"margin-top":"10px"},
          color: "info",
          block: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('next-part')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.startNextButtonName), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}