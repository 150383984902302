<template>
    <v-text-field
        class="pl-6 pt-6 pr-5"
        :value="modelValue"
        type="date"
        :error="modelValue === ''"
        :error-messages="modelValue === '' ? 'Provdag måste väljas!' : undefined"
        variant="outlined"
        @input="$emit('update:modelValue', $event.target.value)"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ChaseInputDaySelect',
    props: {
        modelValue: {
            type: String,
            required: true
        }
    },
    emits: ['update:modelValue']
})
</script>