<!-- eslint-disable vue/no-mutating-props -->
<template>
    <v-expansion-panel>
        <v-expansion-panel-title>
            <v-row no-gutters>
                <v-col class="d-flex align-center">
                    <span class="font-weight-bold">Släpp {{ release.id+1 }}</span>
                </v-col>
            </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="bg-white text-black">
            <v-row
                class="d-flex mt-2"
            >
                <v-col cols="6">
                    <h3>Släpp</h3>
                </v-col>
                <v-col class="pb-0">
                    <v-text-field
                        v-model="release.results['release_time']"
                        :rules="[rules.required]"
                        variant="outlined"
                        type="time"
                        @change="checkIfEmpty($event.target.value, 'release_time')"
                    />
                </v-col>
            </v-row>
            <v-divider />
            <v-row
                class="d-flex mt-2 pt-2"
            >
                <v-col cols="6">
                    <h3>Slag</h3>
                </v-col>
                <v-col class="pb-0">
                    <v-text-field
                        v-model="release.results['release_slag']"
                        :rules="[
                            generateRequiredRuleNormal('release_slag', '>', 'release_time')
                        ]"
                        variant="outlined"
                        type="time"
                        @change="checkIfEmpty($event.target.value, 'release_slag')"
                    />
                </v-col>
            </v-row>
            <v-divider />
            <v-row class="mt-2">
                <v-col cols="6">
                    <h3>Upptag</h3>
                </v-col>
                <v-col class="d-flex justify-end">
                    <h3>
                        {{ 
                            // eslint-disable-next-line max-len
                            release.results['release_uptake']?.length > 1 ? release.results['release_uptake'][0].from || '--:--' : '--:--'
                        }}
                    </h3>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-table
                    density="compact"
                    class="mt-4"
                    style="width: 100%;"
                >
                    <thead>
                        <tr>
                            <th class="pl-0 pr-2">
                                Från
                            </th>
                            <th class="pl-0 pr-2">
                                Till
                            </th>
                            <th class="text-center">
                                Drev
                            </th>
                            <th class="text-center">
                                Tappt
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(uptake, idx) in release.results['release_uptake']"
                            :key="uptake.id"
                            @change="changeUptake()"
                        >
                            <td class="pl-0 pr-2 py-2">
                                <v-text-field
                                    v-model="uptake['from']"
                                    variant="outlined"
                                    type="time"
                                    hide-details
                                    :rules="[
                                        generateBetweenUptakeRule(idx),
                                        uptake.id === 0 ?
                                            generateRequiredRule(
                                                uptake['from'],
                                                release.results['release_slag'],
                                                '>'
                                            ) : 
                                            () => true
                                    ]"
                                    style="min-width: 110px;"
                                />
                            </td>
                            <td class="pl-0 pr-2 py-2">
                                <v-text-field
                                    v-model="uptake['to']"
                                    variant="outlined"
                                    type="time"
                                    hide-details
                                    :rules="[
                                        generateRequiredRuleUptakeNormal('to', '>', 'from', idx)
                                    ]"
                                    style="min-width: 110px;"
                                />
                            </td>
                            <td 
                                style="text-align: center; font-weight: bold;"
                                :class="
                                    getChaseTime(idx) ? 'color-green' : ''"
                            >
                                {{ getChaseTime(idx) || '-' }}
                            </td>
                            <td 
                                style="text-align: center; font-weight: bold;"
                                :class="droppedTimeBetweenUptake(idx) !== '-' ? 'color-red' : ''"
                            >
                                {{ droppedTimeBetweenUptake(idx) }}
                            </td>
                        </tr>
                    </tbody>
                </v-table>
                <div class="uptake-summation">
                    <div class="summation-drev">
                        {{ drevSummation }}
                    </div>
                    <div class="summation-tappt">
                        {{ tapptSummation }}
                    </div>
                </div>
            </v-row>
            <v-divider />
            <v-row class="d-flex align-center mt-2">
                <v-col cols="8">
                    <h3>Sista Drevskall</h3>
                </v-col>
                <v-col class="d-flex justify-end">
                    <h3>
                        {{ 
                            // eslint-disable-next-line max-len
                            release.results['release_uptake']?.length > 1 ? release.results['release_uptake'][release.results['release_uptake'].length - 2].to || '--:--' : '--:--'
                        }}
                    </h3>
                </v-col>
            </v-row>
            <v-divider />
            <div v-if="!eventIsTaxChase">
                <v-row class="d-flex mt-2">
                    <v-col cols="6">
                        <h3>Inkall</h3>
                    </v-col>
                    <v-col class="pb-0">
                        <v-text-field
                            v-model="release.results['recall']"
                            variant="outlined"
                            type="time"
                            :rules="[
                                rules.required,
                            ]"
                            @change="checkIfEmpty($event.target.value, 'recall')"
                        />
                    </v-col>
                </v-row>
                <v-divider />
            </div>
            <v-row class="d-flex mt-2">
                <v-col cols="6">
                    <h3>Kopplad</h3>
                </v-col>
                <v-col class="pb-0">
                    <v-text-field
                        v-model="release.results['leashed']"
                        variant="outlined"
                        type="time"
                        :rules="[
                            rules.required,
                        ]"
                        @change="checkIfEmpty($event.target.value, 'leashed')"
                    />
                </v-col>
            </v-row>
            <v-divider />
            <div v-if="!eventIsTaxChase">
                <v-row class="d-flex mt-2">
                    <v-col cols="6">
                        <h3>Skallfrekvens</h3>
                    </v-col>
                    <v-col class="pb-0">
                        <v-text-field
                            v-model.number="release.results['bark_frequency']"
                            variant="outlined"
                            type="number"
                            :rules="[
                                rules.bark_frequency
                            ]"
                            @change="checkIfEmpty($event.target.value, 'bark_frequency')"
                        />
                    </v-col>
                </v-row>
                <v-divider />
            </div>
            <v-row class="d-flex align-center mt-2">
                <v-col cols="8">
                    <h3>Loss Tid</h3>
                </v-col>
                <v-col class="d-flex justify-end">
                    <h3>{{ lossTime[index] }}</h3>
                </v-col>
            </v-row>
            <v-divider />
            <v-row class="d-flex mt-2">
                <v-col cols="6">
                    <h3>Viltslag</h3>
                </v-col>
                <v-col class="pb-0">
                    <v-select
                        v-model="release.results['gamekind']"
                        variant="outlined"
                        :rules="[
                            rules.required
                        ]"
                        :items="animals"
                    />
                </v-col>
            </v-row>
            <v-divider />
            <v-row class="d-flex mt-2">
                <v-col cols="6">
                    <h3>Använt pejl för inkallning?</h3>
                </v-col>
                <v-col class="pb-0">
                    <v-select
                        v-model="release.results['hunting_pejl']"
                        variant="outlined"
                        :rules="[
                            rules.required
                        ]"
                        :items="[
                            {title: 'Ja', value: 'yes'}, 
                            {title: 'Nej', value: 'no'}
                        ]"
                    />
                </v-col>
            </v-row>

            <v-row 
                no-gutters 
                class="d-flex align-center justify-end mt-8"
            >
                <v-btn
                    variant="plain"
                    color="error"
                    @click="removeLetGo(index)"
                >
                    Ta bort släpp
                    <v-icon class="ml-1">
                        mdi-close-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="tonal"
                    color="info"
                    class="ml-2"
                    @click="openPanel = []"
                >
                    <v-icon>
                        mdi-chevron-up
                    </v-icon>
                </v-btn>
            </v-row>
        </v-expansion-panel-text>
    </v-expansion-panel>
</template>

<script lang="ts">
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable vue/no-mutating-props */
import { defineComponent } from 'vue';

declare type ValidationRule = any;

export default defineComponent({
    name: 'ReleasePanel',
    props: {
        release: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        openPanel: {
            type: Array,
            required: true,
        },
        rules: {
            type: Object,
            required: true,
        },
        lossTime: {
            type: String,
            required: true,
        },
        animals: {
            type: Array,
            required: true,
        },
        eventType: {
            type: String,
            required: true,
        },
        eventIsTaxChase: {
            type: Boolean,
            required: true,
        },
        convertToSecs: {
            type: Function,
            required: true,
        }
    },
    emits: ['removeLetGo', 'checkIfEmpty', 'changeUptake'],
    computed: {
        drevSummation() {
            let summation = 0;
            const uptake = this.release.results.release_uptake
            uptake.forEach((v: any) => {
                const from = this.convertToSecs(v.from);
                const to = this.convertToSecs(v.to);

                if (Number.isNaN(from) || Number.isNaN(to)) {
                    return;
                }

                summation += (to - from) / 60;
            });

            return summation;
        },

        tapptSummation() {
            let summation = 0;
            const uptake = this.release.results.release_uptake
            uptake.forEach((v: any, index: number) => {
                const to = this.convertToSecs(v.to);
                const fromNext = uptake[index + 1] ? this.convertToSecs(uptake[index + 1].from) : NaN

                if (Number.isNaN(fromNext) || Number.isNaN(to)) {
                    return;
                }

                summation += (fromNext - to) / 60;
            });

            return summation;
        },
    },
    methods: {
        removeLetGo(index: number): void {
            this.$emit('removeLetGo', index);
        },

        changeUptake() {
            this.$emit('changeUptake', this.index, this.release.results);
        },

        convertSecsToHrMin(seconds: number): string {
            let nSec = seconds;

            const hr = Math.floor(nSec / 3600);
            nSec -=  hr * 3600;
            const min = Math.floor(nSec / 60);

            return `${hr}:${min > 9 ? min : `0${min}`}`
        },

        checkIfEmpty(value: string, scope: string): void {
            this.$emit('checkIfEmpty', value, this.index, scope);
        },

        droppedTimeBetweenUptake(
            currentUptakeIndex: number
        ): string {
            const uptakes = this.release.results.release_uptake;
            const currentUptake = uptakes[currentUptakeIndex]
            const findLaterUptake = uptakes.find((uTake: any) => uTake.id === currentUptake.id + 1);

            if (!findLaterUptake || !currentUptake) {
                return '-';
            }
            if (currentUptake.to === '' || findLaterUptake.from === '') {
                return '-';
            }

            const tapptTime = `${Math.abs(this.convertToSecs(currentUptake.to) - this.convertToSecs(findLaterUptake.from)) / 60 }`;

            currentUptake.tappt_time = tapptTime;
            return tapptTime;
        },

        getChaseTime(
            currentUptakeIndex: number
        ): number {
            const uptakes = this.release.results.release_uptake;
            const currentUptake = uptakes[currentUptakeIndex]

            const chaseTime = `${Math.abs(
                this.convertToSecs(currentUptake.from) - this.convertToSecs(currentUptake.to)
            ) / 60}`;

            currentUptake.chase_time = chaseTime;
            return Number(chaseTime);
        },

        generateBetweenUptakeRule(
            currentUptakeIndex: number
        ): ValidationRule {
            const uptakes = this.release.results.release_uptake;
            const currentUptake = uptakes[currentUptakeIndex]
            const findEarlierUptake = uptakes.find((uTake: any) => uTake.id === currentUptake.id - 1);

            if (!findEarlierUptake || !currentUptake) {
                return () => true;
            }

            return this.generateRequiredRule(
                currentUptake.from, 
                findEarlierUptake.to,
                '>'
            );
        },

        generateRequiredRuleUptakeNormal(
            scope1: string, 
            checkType: string, 
            scope2: string,
            currentUptakeIndex: number
        ): ValidationRule {
            return this.generateRequiredRule(
                this.release.results.release_uptake[currentUptakeIndex][scope1],
                this.release.results.release_uptake[currentUptakeIndex][scope2],
                checkType
            );
        },

        generateRequiredRuleNormal(scope1: string, checkType: string, scope2: string): ValidationRule {
            return this.generateRequiredRule(
                this.release.results[scope1], 
                this.release.results[scope2], 
                checkType
            );
        },

        generateRequiredRule(input1: any, input2: any, checkType: string): ValidationRule {
            if (!input1 || !input2) {
                return () => true;
            }

            const value1 = this.convertToSecs(input1);
            const value2 = this.convertToSecs(input2);

            if (checkType === '>') {
                return () => value1 > value2 || 'De föregående värdet måste vara senare';
            }
            if (checkType === '<') {
                return () => value1 < value2 || 'De påföljande värdet måste vara senare';
            }
            
            return () => 'Okänt fel'
        },
    }
});
</script>