<template>
    <v-btn
        :color="isNoShow ? 'error' : getColor(controlPassed)"
        :prepend-icon="getIcon(controlPassed)"
        @click="$emit('click-control')"
    >
        FÖRHANDSKONTROLL
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.condition)"
        :prepend-icon="getIcon(hasEval.condition)" 
        @click="$emit('click-bed', 'condition')"
    >
        FÖRUTSÄTTNINGAR
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.baitwork)"
        :prepend-icon="getIcon(hasEval.baitwork)" 
        @click="$emit('click-bed', 'baitwork')"
    >
        LOCKARBETE
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.workshot)"
        :prepend-icon="getIcon(hasEval.workshot)" 
        @click="$emit('click-bed', 'workshot')"
    >
        ARBETE EFTER SKOTT
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.summary)"
        :prepend-icon="getIcon(hasEval.summary)" 
        @click="$emit('click-bed', 'summary')"
    >
        SAMMANFATTANDE KRITIK
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.price)"
        :prepend-icon="getIcon(hasEval.price)" 
        @click="$emit('click-bed', 'price')"
    >
        PRIS
    </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    useRegistrationsStore
} from '@/stores/store'

export default defineComponent({
    props: {
        controlPassed: {
            type: Number,
            default: -1
        },
        currReg: {
            type: Object,
            required: true,
        },
        isNoShow: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'click-bed': null,
        'click-paid': null,
        'click-control': null
    },
    data() {
        return {
            registration: useRegistrationsStore(),
            hasEval: {
                condition: -1,
                baitwork: -1,
                workshot: -1,
                summary: -1,
                price: -1
            }
        }
    },
    created() {
        Object.keys(this.hasEval).forEach(async (type) => {
            try {
                const done = await this.registration.getHasResult(this.currReg.id, type)
                if (Object.hasOwn(this.hasEval, type)) {
                    // @ts-ignore
                    this.hasEval[type] = done;
                    return;
                }
                throw Error(`[tolling@Actions] Object dosent have eval type ${type}`);
            } catch (error) {
                console.error(error);
            }
        });
    },
    methods: {
        castValue(toCast: number|boolean): number {
            // eslint-disable-next-line no-nested-ternary
            return (typeof toCast === 'boolean') ? (toCast ? 1 : -1) : toCast
        },

        getIcon(val: number|boolean) {
            return this.castValue(val) === 1 ? 'mdi-check-circle-outline' : '';
        },

        getColor(val: number|boolean) {
            if (this.isNoShow) {
                return 'grey';
            }

            switch (this.castValue(val)) {
                case -1:
                    return 'grey'
                case 0:
                    return 'warning'
                case 1:
                    return 'success'
                default:
                    return 'grey'
            }
        }
    },
})
</script>
