import { initializeApp } from 'firebase/app';
import {
    initializeFirestore, 
    CACHE_SIZE_UNLIMITED,
    persistentLocalCache,
    persistentSingleTabManager
} from "firebase/firestore"

const windowEnv = (window as any).env

const firebaseConfig = {
    apiKey: windowEnv.VUE_APP_GOOGLE_API_KEY || process.env.VUE_APP_GOOGLE_API_KEY,
    authDomain: windowEnv.VUE_APP_GOOGLE_AUTH_DOMAIN || process.env.VUE_APP_GOOGLE_AUTH_DOMAIN,
    databaseURL: windowEnv.VUE_APP_GOOGLE_DATABASE_URL || process.env.VUE_APP_GOOGLE_DATABASE_URL,
    projectId: windowEnv.VUE_APP_GOOGLE_PROJECT_ID || process.env.VUE_APP_GOOGLE_PROJECT_ID,
    storageBucket: windowEnv.VUE_APP_GOOGLE_STORAGE_BUCKET || process.env.VUE_APP_GOOGLE_STORAGE_BUCKET,
    messagingSenderId: windowEnv.VUE_APP_GOOGLE_MESSAGING_SENDER_ID || process.env.VUE_APP_GOOGLE_MESSAGING_SENDER_ID,
    appId: windowEnv.VUE_APP_GOOGLE_APP_ID || process.env.VUE_APP_GOOGLE_APP_ID
};

const firestoreConfig = {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
};

const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, {
    localCache: persistentLocalCache({
        tabManager: persistentSingleTabManager({
            // forceOwnership for web worker
            forceOwnership: true
        })
    })
});

/*
    enableIndexedDbPersistence Enables offline capabilites for firestore 
    but triggers mutations errors during development
*/
// enableIndexedDbPersistence(db)
//     .catch((err) => {
//         if (err.code === 'failed-precondition') {
//             // eslint-disable-next-line no-console
//             console.error(`(${err.code}): Multiple tabs open`);
//         } else if (err.code === 'unimplemented') {
//             // eslint-disable-next-line no-alert
//             alert(`(${err.code}): Your browser does not support presistence`);
//         }
//     });

export default db;
