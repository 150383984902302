import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, {
    "full-height": true,
    class: _normalizeClass({'has-nav': _ctx.showNav})
  }, {
    default: _withCtx(() => [
      (_ctx.showNav)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_nav_bar)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_layout, { class: "content" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}