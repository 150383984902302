<template>
    <div>
        <TrackingTopBar
            :tracking-state="trackingState"
            :parts-list="parts"
        />
        <TrackingActionControls
            :tracking-state="trackingState"
            :parts-list="parts"
            @next-part="setNextPart"
        />
        <TrackingPartList
            v-if="evaluationStore.initialized"
            :part-values="partValues"
            :part-list="parts"
            :tracking-state="trackingState"
            :registration-class="registrationClass"
            @set-round-data="setRoundData"
            @update-part-values="updatePartValues"
            @save-run="saveRun"
            @change="onChange"
            @open-previous-modal="previousModal"
            @open-next-modal="nextModal"
            @set-current-modal="setCurrentModal"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import bloodtracking from '@/assets/jsonnet/bloodtracking.jsonnet'

import TrackingTopBar from '@/components/registration/bloodtracking/tracking/TrackingTopBar.vue'
import TrackingActionControls from '@/components/registration/bloodtracking/tracking/TrackingActionControls.vue';
import TrackingPartList from '@/components/registration/bloodtracking/tracking/TrackingPartList.vue';

import { useEvaluationsStore, useEventStore, useRegistrationsStore } from '@/stores/store';

export default defineComponent({
    name: 'BloodtrackingView',
    components: {
        TrackingTopBar,
        TrackingActionControls,
        TrackingPartList
    },
    data() {
        return {
            trackingState: {
                started: false,
                finished: false,
                currentPart: 0,
            },
            partValues: [] as Array<{
                id: number,
                name: string,
                stateName: string
                time: { minutes: number, seconds: number },
                worldTime: Date,
                showModal: boolean,
            }>,
            parts: [] as Array<{ id: number, name: string, stateName: string }>,
            eventStore: useEventStore(),
            registrationStore: useRegistrationsStore(),
            evaluationStore: useEvaluationsStore(),
        }
    },

    computed: {
        registrationClass() {
            const registration = this.registrationStore.getRegistrationByID(this.$route.params.regId.toString());
            if (registration) {
                return registration.class.toLowerCase().replace(' ', '_').replace('ö', 'o');
            }
            return '';
        },
    },

    async mounted() {
        if (!this.eventStore.events.length) {
            await this.eventStore.init();
        }
        if (!this.registrationStore.registrations.length) {
            await this.registrationStore.init(this.eventStore.getEventByID(this.$route.params.eventId.toString()))
        }
        if (!this.evaluationStore.evaluations.length) {
            await this.evaluationStore.init(
                this.registrationStore.getRegistrationByID(this.$route.params.regId as string)
            );
        }

        if (this.registrationClass !== '') {
            this.parts = bloodtracking.step_list[this.registrationClass]
        }
    },

    methods: {
        setCurrentModal(id: number) {
            this.trackingState.currentPart = id;
        },

        nextModal() {
            if (!this.partValues) {
                return;
            }
            if (this.parts.length === (this.trackingState.currentPart - 1)) {
                if (!this.partValues[this.trackingState.currentPart]) {
                    const fModal = this.partValues.find((v: any) => v.id === (this.partValues.length - 1));
                    if (fModal) {
                        fModal.showModal = false;
                    }
                    return;
                }
                const fModal = this.partValues.find((v: any) => v.id === this.trackingState.currentPart);
                if (fModal) {
                    fModal.showModal = false;
                }
                return;
            }
            const fModal = this.partValues.find((v: any) => v.id === (this.trackingState.currentPart + 1));
            if (fModal) {
                fModal.showModal = true;
                this.trackingState.currentPart += 1;
                return;
            }

            this.setNextPart();
        },

        previousModal() {
            const fModal = this.partValues.find((v: any) => v.id === (this.trackingState.currentPart - 1))
            if (!fModal) {
                const fModal2 = this.partValues.find((v: any) => v.id === this.trackingState.currentPart)
                if (fModal2) {
                    fModal2.showModal = false;
                }
                return;
            }
            fModal.showModal = true;
            this.trackingState.currentPart -= 1;
        },

        setNextPart() {
            if (this.trackingState.finished) {
                return;
            }
            
            if (!this.trackingState.started) {
                this.trackingState.started = true;
            }

            if (this.parts.length === this.trackingState.currentPart) {
                this.trackingState.finished = true;
                return;
            }

            const now = new Date();

            this.partValues[this.trackingState.currentPart] = {
                id: this.parts[this.trackingState.currentPart].id,
                name: this.parts[this.trackingState.currentPart].name,
                stateName: this.parts[this.trackingState.currentPart].stateName,
                time: { minutes: 0, seconds: 0 },
                worldTime: now,
                showModal: true,
            };

            this.trackingState.currentPart += 1;

        },

        updatePartValues() {
            this.registrationStore.addResult(this.$route.params.regId.toString(), 'steps', this.partValues, 'stateName')
        },

        setRoundData(roundData: Array<
            {
                id: number,
                name: string,
                stateName: string
                time: { minutes: number, seconds: number },
                worldTime: Date,
                showModal: boolean
            }>) {

            this.partValues = roundData;
            this.trackingState.currentPart = roundData.length;
            this.trackingState.started = roundData.length > 0;
            this.trackingState.finished = 
                this.trackingState.currentPart >= (bloodtracking.step_list[this.registrationClass].length);
        },

        async saveRun() {
            await this.registrationStore.MarkDone(this.$route.params.regId as string, 'steps');
            this.$router.push({ name: 'RegistrationView' });
        },

        onChange(evalChanges: Array<Object>) {
            this.registrationStore.addResult(
                this.$route.params.regId as string,
                'steps',
                evalChanges
            )
        }
    }
})

</script>