import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_evaluation_label = _resolveComponent("evaluation-label")!

  return (_ctx.foundChase && _ctx.renderableEntries)
    ? (_openBlock(), _createBlock(_component_v_radio_group, {
        key: 0,
        modelValue: _ctx.selected[_ctx.input.scope],
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
        style: {"margin-top":"0px !important"},
        "hide-details": _ctx.hideDetails
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderableEntries, (option) => {
            return (_openBlock(), _createBlock(_component_v_radio, {
              key: option.id,
              label: `Släpp ${option.id + 1} (${_ctx.translation[option.results.gamekind]})`,
              value: option.id,
              outlined: "",
              color: "info"
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "hide-details"]))
    : (_openBlock(), _createBlock(_component_evaluation_label, {
        key: 1,
        input: {
            value: `Kunde inte hitta några slutförda drev för ${_ctx.getTitleFromScope(_ctx.input.scope)}`,
            class: 'warning'
        }
      }, null, 8, ["input"]))
}