<template>
    <v-text-field 
        v-if="dynamic !== null && dynamicIndex !== null"
        v-model="dynamic[dynamicIndex][input.scope]"
        type="text"
        variant="outlined"
        :hide-details="hideDetails"
        outlined
        :placeholder="input.title ?? ''"
    />
    <v-text-field
        v-else
        v-model="selected[input.scope]"
        type="text"
        variant="outlined"
        :hide-details="hideDetails"
        outlined
        :placeholder="input.title ?? ''"
    />
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'e-textfield',
    props: {
        dynamic: {
            type: Object,
            default: null
        },
        dynamicIndex: {
            type: Number,
            default: null
        },
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
    created() {
        if (this.dynamic !== null && this.dynamicIndex !== null) {
            if (!(this.input.scope in this.dynamic[this.dynamicIndex])) {
                this.dynamic[this.dynamicIndex][this.input.scope] = ''
            }
        }
    },
})

</script>