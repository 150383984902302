<template>
    <div>
        <v-dialog 
            v-model="showControl"
            contained
        >
            <v-card width="450px" style="max-width: 100%">
                <v-card-title align="center">
                    KONTROLLERA
                </v-card-title>
                <v-card-text>
                    <v-col align="center">
                        <span>CHIPNUMMER</span>
                        <h4 style="margin-bottom: 10px;">
                            {{ registration.chip }}
                        </h4>

                        <span>REGISTRERINGSNUMMER</span>
                        <h4>{{ registration.regnum }}</h4>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-col 
                        v-if="!requestControlReason" 
                        class="v-card-button-col"
                    >
                        <v-btn
                            style="margin: 0px 0px 0px 0px;"
                            variant="tonal" 
                            color="green" 
                            block 
                            @click="controll('ok')"
                        >
                            ID-KONTROLL AV HUND ÄR OK
                        </v-btn>
                        <v-btn
                            style="margin: 5px 0px 0px 0px;"
                            variant="tonal" 
                            color="orange" 
                            block 
                            @click="requestControlReason = true" 
                        >
                            ID-KONTROLL AV HUND ÄR FELAKTIGT
                        </v-btn>
                        <v-btn
                            style="margin: 5px 0px 0px 0px;"
                            variant="tonal" 
                            color="error" 
                            block 
                            @click="$emit('no-show')" 
                        >
                            KOM EJ TILL START
                        </v-btn>
                        <v-btn
                            style="margin: 5px 0px 0px 0px;"
                            block 
                            @click="$emit('cancle-control')" 
                        >
                            AVBRYT
                        </v-btn>
                    </v-col>
                    <v-col
                        v-else
                        class="v-card-button-col" 
                        align="center"
                    >
                        <h3>Anledning</h3>
                        <v-textarea 
                            v-model="controlBadReason"
                        />

                        <v-btn
                            style="margin: 0px;"
                            variant="tonal" 
                            color="orange" 
                            :disabled="controlBadReason === ''" 
                            block
                            @click="controll('bad')"
                        >
                            SPARA
                        </v-btn>
                        <v-btn
                            style="margin: 0px;"
                            block
                            @click="requestControlReason = false"
                        >
                            Tillbaka
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
import { useRegistrationsStore } from '@/stores/store';
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        showControl: Boolean,
        registration: {
            type: Object,
            default: () => {}
        },
    },
    emits: {
        'control-ok': null, 
        'control-bad': null, 
        'cancle-control': null,
        'no-show': null
    },
    data() {
        return {
            requestControlReason: false,
            controlBadReason: '',
            registrationStore: useRegistrationsStore(),
        }
    },
    async created() {
        const data = await this.registrationStore.getResultData(this.registration.id, 'control') as any;
        if (!data) {
            return;
        }

        if (data.control_result.controll_status === 'not_ok') {
            this.requestControlReason = true;
            this.controlBadReason = data.control_result.reason;
        }
    },
    methods: {
        controll(result: string) {
            if (result === 'ok') {
                this.controlBadReason = '';
                this.requestControlReason = false;

                this.registrationStore.addResult(this.registration.id, 'control', [{
                    type: 'control_result',
                    controll_status: 'ok'
                }]).then(() => {
                    this.registrationStore.MarkDone(this.registration.id, 'control');
                    return true;
                }).catch(() => {});
                
                this.$emit('control-ok');
            } else if (result === 'bad') {
                this.registrationStore.addResult(this.registration.id, 'control', [{
                    type: 'control_result',
                    controll_status: 'not_ok',
                    reason: this.controlBadReason,
                }]).then(() => {
                    this.registrationStore.MarkDone(this.registration.id, 'control');
                    return true;
                }).catch(() => {});

                this.$emit('control-bad', this.controlBadReason);
            }

            this.$emit('cancle-control');
        }
    }
})
</script>