import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TrackingTopBar = _resolveComponent("TrackingTopBar")!
  const _component_TrackingActionControls = _resolveComponent("TrackingActionControls")!
  const _component_TrackingPartList = _resolveComponent("TrackingPartList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TrackingTopBar, {
      "tracking-state": _ctx.trackingState,
      "parts-list": _ctx.parts
    }, null, 8, ["tracking-state", "parts-list"]),
    _createVNode(_component_TrackingActionControls, {
      "tracking-state": _ctx.trackingState,
      "parts-list": _ctx.parts,
      onNextPart: _ctx.setNextPart
    }, null, 8, ["tracking-state", "parts-list", "onNextPart"]),
    (_ctx.evaluationStore.initialized)
      ? (_openBlock(), _createBlock(_component_TrackingPartList, {
          key: 0,
          "part-values": _ctx.partValues,
          "part-list": _ctx.parts,
          "tracking-state": _ctx.trackingState,
          "registration-class": _ctx.registrationClass,
          onSetRoundData: _ctx.setRoundData,
          onUpdatePartValues: _ctx.updatePartValues,
          onSaveRun: _ctx.saveRun,
          onChange: _ctx.onChange,
          onOpenPreviousModal: _ctx.previousModal,
          onOpenNextModal: _ctx.nextModal,
          onSetCurrentModal: _ctx.setCurrentModal
        }, null, 8, ["part-values", "part-list", "tracking-state", "registration-class", "onSetRoundData", "onUpdatePartValues", "onSaveRun", "onChange", "onOpenPreviousModal", "onOpenNextModal", "onSetCurrentModal"]))
      : _createCommentVNode("", true)
  ]))
}