<template>
    <v-radio-group
        v-if="dynamic !== null && dynamicIndex !== null"
        v-model="dynamic[dynamicIndex][input.scope]"
        style="margin-top: 0px !important"
        :hide-details="hideDetails"
    >
        <template v-if="Array.isArray(input.value)">
            <v-radio
                v-for="(option, value) in input.value"
                :key="`${input.scope}_${value}`"
                :value="option.value"
                color="info"
            >
                <template #label>
                    <strong v-if="option.bold">
                        {{ option.name }}
                    </strong>
                    <span v-else>
                        {{ option.name }}
                    </span>
                </template>
            </v-radio>
        </template>
        <template v-else>
            <v-radio
                v-for="value in Object.keys(input.value)"
                :key="`${input.scope}_${value}`"
                :label="input.value[value]"
                :value="Number(value)"
                color="info"
            >
                <template #label>
                    {{ input.value[value] }}
                </template>
            </v-radio>
        </template>
    </v-radio-group>
    <v-radio-group
        v-else
        v-model="selected[input.scope]"
        style="margin-top: 0px !important"
        :hide-details="hideDetails"
    >
        <template v-if="Array.isArray(input.value)">
            <v-radio
                v-for="(option, value) in input.value"
                :key="`${input.scope}_${value}`"
                :value="option.value"
                color="info"
            >
                <template #label>
                    <strong v-if="option.bold">
                        {{ option.name }}
                    </strong>
                    <span
                        v-else
                        :style="option.mark ? `color: ${option.mark};` : ''"
                    >
                        {{ option.name }}
                    </span>
                </template>
            </v-radio>
        </template>
        <template v-else>
            <v-radio
                v-for="value in Object.keys(input.value)"
                :key="`${input.scope}_${value}`"
                :label="input.value[value]"
                :value="Number(value)"
                color="info"
            >
                <template #label>
                    {{ input.value[value] }}
                </template>
            </v-radio>
        </template>
    </v-radio-group>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ERadio',
    props: {
        dynamic: {
            type: Object,
            default: null
        },
        dynamicIndex: {
            type: Number,
            default: null
        },
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {},
        },
        input: {
            type: Object,
            default: () => {},
        },
        values: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['setScope'],
    created() {
        if (!this.input.default) {
            return;
        }

        const { scope } = this.input;
        const inputValue = this.values.find((value: any) => value.type === scope) as any;

        if (inputValue && inputValue.value_text !== this.input.default) {
            return;
        }

        this.$emit('setScope', this.input.scope, this.input.default);
    },
});
</script>
