<template>
    <div>
        <div class="mb-8 name-div">
            <h2 class="stationName mb-2">
                {{ stationName }}
            </h2>
            <v-divider />
        </div>


        <v-btn
            v-for="btnInput in workingTestInputs"
            :key="btnInput.id"
            :color="btnInput.id === currentSelected ? btnInput.active_color : btnInput.color"
            class="mb-2"
            :style="(btnInput.id !== currentSelected) ? 'opacity: 0.5' : ''"
            block
            variant="elevated"
            @click="toggleSelected(btnInput.id)"
        >
            <span style="color: black">{{ btnInput.name }}</span>
        </v-btn>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'EvaluationWorkingTestInput',
    props: {
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => { }
        },
        input: {
            type: Object,
            default: () => { }
        },
        values: {
            type: Array,
            default: () => []
        },
    },
    emits: ['setScope'],
    data() {
        return {
            workingTestInputs: [
                { active_color: 'grey-lighten-4', color: 'grey-lighten-5', id: 0, name: '0', value: 0 },
                { active_color: 'brown-lighten-4', color: 'brown-lighten-5', id: 1, name: '1', value: 1 },
                { active_color: 'brown-lighten-4', color: 'brown-lighten-5', id: 2, name: '2', value: 2 },
                { active_color: 'brown-lighten-4', color: 'brown-lighten-5', id: 3, name: '3', value: 3 },
                { active_color: 'brown-lighten-4', color: 'brown-lighten-5', id: 4, name: '4', value: 4 },
                { active_color: 'deep-purple-lighten-4', color: 'deep-purple-lighten-5', id: 5, name: '5', value: 5 },
                { active_color: 'deep-purple-lighten-4', color: 'deep-purple-lighten-5', id: 6, name: '6', value: 6 },
                { active_color: 'deep-purple-lighten-4', color: 'deep-purple-lighten-5', id: 7, name: '7', value: 7 },
                { active_color: 'deep-purple-lighten-4', color: 'deep-purple-lighten-5', id: 8, name: '8', value: 8 },
                { active_color: 'deep-purple-lighten-4', color: 'deep-purple-lighten-5', id: 9, name: '9', value: 9 },
                { active_color: 'yellow-lighten-4', color: 'yellow-lighten-5', id: 10, name: '10', value: 10 },
                { active_color: 'yellow-lighten-4', color: 'yellow-lighten-5', id: 11, name: '11', value: 11 },
                { active_color: 'yellow-lighten-4', color: 'yellow-lighten-5', id: 12, name: '12', value: 12 },
                { active_color: 'blue-lighten-4', color: 'blue-lighten-5', id: 13, name: '13', value: 13 },
                { active_color: 'blue-lighten-4', color: 'blue-lighten-5', id: 14, name: '14', value: 14 },
                { active_color: 'blue-lighten-4', color: 'blue-lighten-5', id: 15, name: '15', value: 15 },
                { active_color: 'green-lighten-4', color: 'green-lighten-5', id: 16, name: '16', value: 16 },
                { active_color: 'green-lighten-4', color: 'green-lighten-5', id: 17, name: '17', value: 17 },
                { active_color: 'green-lighten-4', color: 'green-lighten-5', id: 18, name: '18', value: 18 },
                { active_color: 'green-lighten-4', color: 'green-lighten-5', id: 19, name: '19', value: 19 },
                { active_color: 'green-lighten-4', color: 'green-lighten-5', id: 20, name: '20', value: 20 },
                { active_color: 'red-lighten-3', color: 'red-lighten-4', id: 21, name: 'Brutit', value: 'diverge' },
                { active_color: 'red-lighten-2', color: 'red-lighten-3', id: 22, name: 'Diskvalificerad', value: 'disqualified' },
                { active_color: 'red-darken-4', color: 'red-darken-3', id: 23, name: 'Diskvalificerad på grund av mentalitet', value: 'disqualified_mentality' },
                { active_color: 'grey-darken-2', color: 'grey-darken-3', id: -1, name: 'Inget värde', value: -1 },
            ],
            currentSelected: -1,
        }
    },
    computed: {
        stationName() {
            return this.$route.params.type.toString().replace('_', ' ');
        }
    },
    mounted() {
        const thisInput = this.values.find((v: any) => v.type === this.input.scope) as any;

        if (!thisInput) {
            return;
        }

        const corrispondingInputId = this.workingTestInputs.find((v) => v.value === thisInput.value)?.id

        if (!corrispondingInputId) {
            return;
        }

        this.currentSelected = corrispondingInputId;
    },
    methods: {
        toggleSelected(id: number) {
            this.currentSelected = id;

            const selectedValue = this.workingTestInputs.find((v) => v.id === id)?.value;
            this.$emit('setScope', this.input.scope, selectedValue);
        }
    }
})

</script>

<style scoped>
.name-div {
    cursor: auto;
}

.stationName {
    text-transform: capitalize;
}
</style>
