<template>
    <v-select
        v-model="selected[input.scope]"
        :label="input.placeholder"
        :items="input.values"
        item-title="name"
        item-value="value"
        :hide-details="hideDetails"
        multiple
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'EMultipleSelect',
    props: {
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
})

</script>