<template>
    <div>
        <FollowUpTitle>
            Förbättringspunkter att följa upp
        </FollowUpTitle>
        <v-row class="my-0">
            <v-col>
                <v-table>
                    <thead>
                        <tr>
                            <th class="test-left">
                                Förbättringspunkter
                            </th>
                            <th class="test-left">
                                Svarat
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="deficiency in deficiencies"
                            :key="deficiency.key"
                        >
                            <td>{{ deficiency.name }}</td>
                            <td>{{ deficiency.value }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FollowUpTitle from './FollowUpTitle.vue';


export default defineComponent({
    name: 'DeficiencyFormatter',
    components: { FollowUpTitle },
    props: {
        deficiencies: {
            type: Array<{
                key: string;
                name: string;
                value: string;
            }>,
            required: true
        }
    },
})

</script>