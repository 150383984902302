import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "title"
}
const _hoisted_2 = {
  key: 1,
  class: "title"
}
const _hoisted_3 = {
  key: 0,
  align: "left",
  class: "inputtitle"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { style: {"margin-top":"10px"} }
const _hoisted_8 = {
  key: 0,
  class: "a-title"
}
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "text-white" }
const _hoisted_11 = { class: "text-white" }
const _hoisted_12 = { class: "text-white" }
const _hoisted_13 = { key: 3 }
const _hoisted_14 = {
  key: 5,
  class: "red--text font-weight-bold float-left text-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_EvaluationInput = _resolveComponent("EvaluationInput", true)!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_comment_inline = _resolveComponent("comment-inline")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.disable ? 'pointer-events: none; user-select: none; opacity: 0.5;' : '')
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeInputs, (input, idx) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
        _createVNode(_component_v_row, {
          class: _normalizeClass([_ctx.inline ? 'my-4' : 'my-0'])
        }, {
          default: _withCtx(() => [
            ((_ctx.title || input.title) && _ctx.inline)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  align: "left",
                  class: "my-0 py-0",
                  cols: "8"
                }, {
                  default: _withCtx(() => [
                    (_ctx.title)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.title), 1))
                      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(input.title), 1))
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            _createVNode(_component_v_col, { class: "my-0 py-0" }, {
              default: _withCtx(() => [
                (!_ctx.inline &&
                            (input.title || _ctx.title) &&
                            !['group', 'dynamic'].includes(input.type))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      (_ctx.title)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.title), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(input.title), 1))
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(), _createBlock(_resolveDynamicComponent('e-' + input.type), {
                  selected: _ctx.selected,
                  input: input,
                  "hide-details": _ctx.hideDetails,
                  values: _ctx.values,
                  dynamic: _ctx.dynamic,
                  "dynamic-index": _ctx.dynamicIndex,
                  registration: _ctx.registration,
                  onChange: _ctx.onChange,
                  onSetScope: _cache[0] || (_cache[0] = (scope, data) => _ctx.setScopeData(scope, data))
                }, null, 40, ["selected", "input", "hide-details", "values", "dynamic", "dynamic-index", "registration", "onChange"])),
                (input.type === 'activator')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_v_radio_group, {
                        modelValue: _ctx.selected[input.scope],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.selected[input.scope]) = $event),
                        style: {"margin-top":"0px !important"},
                        "hide-details": _ctx.hideDetails
                      }, {
                        default: _withCtx(() => [
                          (Array.isArray(input.value))
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(input.value, (option, value) => {
                                return (_openBlock(), _createBlock(_component_v_radio, {
                                  key: `${input.scope}_${value}`,
                                  label: option.name,
                                  value: option.value,
                                  color: "info"
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(Object.keys(input.value), (value) => {
                                return (_openBlock(), _createBlock(_component_v_radio, {
                                  key: `${input.scope}_${value}`,
                                  label: input.value[value],
                                  value: Number(value),
                                  color: "info"
                                }, null, 8, ["label", "value"]))
                              }), 128))
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "hide-details"]),
                      _withDirectives(_createElementVNode("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(input.hidden_inputs, (hInput, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            (hInput.title)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                  _createTextVNode(_toDisplayString(hInput.title) + " ", 1),
                                  _createVNode(_component_v_divider)
                                ]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_EvaluationInput, {
                              ref_for: true,
                              ref: "dynRefs",
                              inputs: hInput.inputs,
                              scope: hInput.scope,
                              values: _ctx.values,
                              onChange: _ctx.onChange
                            }, null, 8, ["inputs", "scope", "values", "onChange"])
                          ]))
                        }), 128))
                      ], 512), [
                        [_vShow, _ctx.selected[input.scope] == input.activate_value]
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (input.type === 'dynamic')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_v_expansion_panels, {
                        modelValue: _ctx.showDynamic,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDynamic) = $event)),
                        variant: "accordion"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected[input.scope], (_, index) => {
                            return (_openBlock(), _createBlock(_component_v_expansion_panel, { key: index }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_expansion_panel_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(input.title) + " " + _toDisplayString(index + 1), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_expansion_panel_text, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_EvaluationInput, {
                                      ref_for: true,
                                      ref: "dynRefs",
                                      inputs: input.inputs,
                                      scope: input.scope,
                                      values: _ctx.values,
                                      dynamic: _ctx.selected[input.scope],
                                      "dynamic-index": index,
                                      "hide-details": true
                                    }, null, 8, ["inputs", "scope", "values", "dynamic", "dynamic-index"]),
                                    _createVNode(_component_v_btn, {
                                      class: "mt-5",
                                      color: "green",
                                      block: "",
                                      rounded: "",
                                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDynamic = false))
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_10, "Spara " + _toDisplayString(input.title), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_btn, {
                                      class: "mt-1",
                                      color: "red",
                                      block: "",
                                      rounded: "",
                                      onClick: ($event: any) => (_ctx.removeDynamic(input.scope, index))
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_11, "Ta bort " + _toDisplayString(input.title), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["modelValue"]),
                      _createVNode(_component_v_row, { class: "mt-5 mb-4 pl-5 pr-5" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                color: "blue",
                                block: "",
                                rounded: "",
                                onClick: ($event: any) => (_ctx.addDynamic(input.scope))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_12, "Lägg till " + _toDisplayString(input.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      (_openBlock(), _createBlock(_resolveDynamicComponent('datahandler-' + input.scope), {
                        values: _ctx.selected[input.scope]
                      }, null, 8, ["values"]))
                    ]))
                  : _createCommentVNode("", true),
                (input.type === 'group')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(_component_EvaluationInput, {
                        title: input.title,
                        inputs: input.inputs,
                        scope: input.scope,
                        values: _ctx.values
                      }, null, 8, ["title", "inputs", "scope", "values"])
                    ]))
                  : _createCommentVNode("", true),
                (input.comment && (input.type !== 'checkbox' || _ctx.selected[input.scope]))
                  ? (_openBlock(), _createBlock(_component_comment_inline, {
                      key: 4,
                      input: input,
                      comment: _ctx.comments[input.scope],
                      "onUpdate:modelValue": (updatedComment) => _ctx.updateComment(input.scope, updatedComment)
                    }, null, 8, ["input", "comment", "onUpdate:modelValue"]))
                  : _createCommentVNode("", true),
                (_ctx.warning[input.scope])
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.warning[input.scope]), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["class"]),
        (_ctx.inline)
          ? (_openBlock(), _createBlock(_component_v_divider, {
              key: `divider-${idx}`
            }))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    (_ctx.divider && !_ctx.inline)
      ? (_openBlock(), _createBlock(_component_v_divider, { key: 0 }))
      : _createCommentVNode("", true)
  ], 4))
}