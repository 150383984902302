<template>
    <v-text-field
        v-if="dynamic !== null && dynamicIndex !== null"
        v-model.number="dynamic[dynamicIndex][input.scope]"
        type="number"
        inputmode="numeric"
        variant="outlined"
        :hide-details="hideDetails"
        outlined
        :placeholder="input.title ?? ''"
        @change="checkInputRules()"
    />
    <v-text-field
        v-else
        v-model.number="selected[input.scope]"
        type="number"
        inputmode="numeric"
        variant="outlined"
        :hide-details="hideDetails"
        outlined
        :placeholder="input.title ?? ''"
        @change="checkInputRules()"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'e-numberfield',
    props: {
        dynamic: {
            type: Object,
            default: null
        },
        dynamicIndex: {
            type: Number,
            default: null
        },
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
    emits: ['setScope'],
    created() {
        if (this.dynamic !== null && this.dynamicIndex !== null) {
            if (!(this.input.scope in this.dynamic[this.dynamicIndex])) {
                this.dynamic[this.dynamicIndex][this.input.scope] = 0;
            }
        }
        
        if (!Object.prototype.hasOwnProperty.call(this.input, 'default') && !Number.isInteger(this.input.default)) {
            return;
        }

        if (Object.prototype.hasOwnProperty.call(this.selected, this.input.scope)) {
            return;
        }

        this.$emit('setScope', this.input.scope, this.input.default);
    },
    methods: {
        checkInputRules() {
            if (this.input.allowNegative) return;

            if (this.dynamic !== null && this.dynamicIndex !== null) {

                if ((this.input.scope in this.dynamic[this.dynamicIndex]) && 
                    this.dynamic[this.dynamicIndex][this.input.scope] < 0
                ) {
                    this.dynamic[this.dynamicIndex][this.input.scope] = 0;
                    return;
                }

                if (this.input.maxValue && 
                    (this.input.scope in this.dynamic[this.dynamicIndex]) && 
                    this.dynamic[this.dynamicIndex][this.input.scope] >= this.input.maxValue
                ) {
                    this.dynamic[this.dynamicIndex][this.input.scope] = this.input.maxValue;
                    return;
                }

                return;
            }

            if(this.selected[this.input.scope] < 0) {
                this.$emit('setScope', this.input.scope, 0);
                return;
            }
            
            if(this.input.maxValue && this.selected[this.input.scope] >= this.input.maxValue)  {
                this.$emit('setScope', this.input.scope, this.input.maxValue);
            }
        },
    },
})

</script>
