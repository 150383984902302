<template>
    <v-col cols="12">
        <div class="page-title d-flex justify-center mb-4">
            <h1>{{ currReg.kennel.name }}</h1>
        </div>

        <v-card
            elevation="3"
            class="d-flex flex-column align-start px-4 py-2 mb-4"
        >
            <v-list class="w-100 pa-0">
                <v-list-item
                    class="mb-2 pa-0"
                    style="min-height: unset;"
                >
                    <div class="d-flex justify-space-between align-center">
                        <h6 class="text-h6">
                            <strong>Kategori:</strong>
                        </h6>
                        <v-chip
                            variant="flat"
                            color="info"
                        >
                            {{ currReg.kennel.category?.name ?? '' }}
                        </v-chip>
                    </div>
                </v-list-item>

                <v-divider />
                
                <v-list-item
                    class="mt-2 pa-0"
                    style="min-height: unset;"
                >
                    <div class="d-flex justify-space-between align-center">
                        <h6 class="text-h6">
                            <strong>Senast godkända besök:</strong>
                        </h6>
                        <div class="d-flex flex-nowrap">
                            <v-btn
                                :href="kennelLink"
                                icon
                                variant="text"
                                size="32"
                                class="mr-1"
                            >
                                <v-icon color="info">
                                    mdi-open-in-new
                                </v-icon>
                            </v-btn>
                            <v-chip
                                variant="flat"
                                color="success"
                            >
                                {{ lastVisitDate }}
                            </v-chip>
                        </div>
                    </div>
                </v-list-item>
            </v-list>
        </v-card>
        
        <v-card
            v-if="ownerVisit"
            elevation="3"
            class="d-flex flex-column px-4 py-2 mb-4 text-subtitle-2"
        >
            <h6 class="mb-1 text-h6">
                <strong>Innehavare</strong>
            </h6>

            <v-divider />

            <div class="mt-2">
                <div>
                    <span>
                        <strong>Namn: </strong>
                    </span>
                    <span>{{ ownerVisit.name }}</span>
                </div>
                <div>
                    <span>
                        <strong>Adress: </strong>
                    </span>
                    <span>{{ currReg.address }}</span>
                </div>
                <div>
                    <span>
                        <strong>Medlem: </strong>
                    </span>
                    <span v-if="ownerVisit.member !== 9">{{ ownerMembership }}</span>
                    <span v-else>Okänt</span>
                </div>
            </div>
        </v-card>

        <v-card
            v-if="ownerVisit"
            elevation="3"
            class="d-flex flex-column px-4 py-2 mb-4 text-subtitle-2"
        >
            <h6 class="mb-1 text-h6">
                <strong>Kontakt</strong>
            </h6>

            <v-divider />

            <div class="d-flex flex-column">
                <div 
                    v-for="(value, key) in contactInfo"
                    :key="key"
                    class="d-flex flex-column mt-2"
                >
                    <a
                        v-for="(v, idx) in value"
                        :key="idx"
                        :href="`${key}:${v}`"
                    >
                        {{ v }}
                    </a>
                </div>
            </div>
        </v-card>

        <v-card
            elevation="3"
            class="d-flex flex-column px-4 py-2 mb-4 text-subtitle-2"
        >
            <h6 class="mb-1 text-h6">
                <strong>Raser</strong>
            </h6>

            <v-divider />

            <div class="mt-2">
                <span
                    v-for="(breed, index) in currReg.kennel.breeds"
                    :key="index"
                >
                    {{ breed.name }}
                </span>
            </div>
        </v-card>

        <v-card
            elevation="3"
            class="d-flex flex-column px-4 py-2 mb-4 text-subtitle-2"
        >
            <h6 class="mb-1 text-h6">
                <strong>Senaste kull</strong>
            </h6>

            <v-divider />

            <div 
                v-if="latestLitter"
                class="d-flex flex-column mt-2"
            >
                <span>
                    <strong>Ras:</strong>
                    {{ latestLitter.breed.name }}
                </span>
                <span>
                    <strong>Moderns reg-nr:</strong>
                    {{ latestLitter.dam }}
                </span>
                <span>
                    <strong>Födelsedag:</strong>
                    {{ latestLitter.birthdate }}
                </span>
                <span>
                    <strong>Hanar:</strong>
                    {{ latestLitter.hanar }}
                </span>
                <span>
                    <strong>Tikar:</strong>
                    {{ latestLitter.tikar }}
                </span>
            </div>
            <div 
                v-else
                class="d-flex flex-column mt-2"
            >
                <strong>Inga kullar registrerade</strong>
            </div>
        </v-card>
    </v-col>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import moment from 'moment';
import { Timestamp } from 'firebase/firestore';

import { useUserStore } from '@/stores/store';

export default defineComponent({
    props: {
        currReg: { type: Object, required: true }
    },
    data() {
        return {
            epToUrl: {
                'skktest': 'https://skk-test.smoothcomp.dev',
                'skkdev': 'https://skk-dev.smoothcomp.dev',
                'skkstart': 'https://skkstart.se',
                'skklocal': 'http://smoothcomp.test',
                'kk': 'https://skkkonsulent.se',
                'kk_dev': 'https://kk-dev.smoothcomp.dev',
                'kk_test': 'https://kk-test.smoothcomp.dev',
                'kk_local': 'https://kk-test.smoothcomp.dev',
            } as { [key: string]: string },
            userStore: useUserStore().getUser()
        }
    },
    computed: {
        kennelLink() {
            const cleanUrl = this.epToUrl[this.userStore.endpoint];
            return `${cleanUrl}/kennel/${this.currReg.kennel.id}`;
        },

        ownerVisit() {
            if (!this.currReg) {
                return null;
            }
            if (!this.currReg.kennel && (!this.currReg.kennel.owners && !Array.isArray(this.currReg.kennel.owners))) {
                return null;
            }
            const ownerVisit = this.currReg.kennel.owners.find((o: any) => o.id === this.currReg.ownerId);
            if (!ownerVisit) {
                return null;
            }
            return ownerVisit;
        },

        contactInfo() {
            if (!this.ownerVisit) {
                return {
                    tel: [],
                    mailto: []
                };
            }
            const tel = [this.ownerVisit.tel_number ? this.ownerVisit.tel_number : null];
            const mob = this.ownerVisit.mobile_number ? this.ownerVisit.mobile_number : null;
            const mail = this.ownerVisit.mail ? this.ownerVisit.mail : null;

            tel.push(mob);

            const filtered_phone = tel.filter((n: any) => n !== null);
            const filtered_mail = [mail].filter((n: any) => n !== null);

            return {
                tel: filtered_phone,
                mailto: filtered_mail
            };
        },

        latestLitter() {
            if (!this.currReg.kennel.litters) {
                return '';
            }

            const litter = this.currReg.kennel.litters;
            litter.sort(
                (a: any, b: any) => {
                    const bDate = Date.parse(b.birthdate);
                    const aDate = Date.parse(a.birthdate);

                    return bDate - aDate;
                }
            );
            const latestLitter = litter[0];

            return latestLitter;
        },


        lastVisitDate() {
            if (!this.currReg.kennel.visit) {
                return 'Inga tidigare besök';
            }

            if (!this.currReg.kennel.visit.last) {
                return 'Inga tidigare besök';
            }

            if (Object.prototype.hasOwnProperty.call(this.currReg.kennel.visit.last, 'seconds')) {
                const ts = this.currReg.kennel.visit.last as Timestamp
                return moment(ts.toDate()).format('YYYY-MM-DD')
            }

            if (this.currReg.kennel.visit.last === '0001-01-01') {
                return 'Inga tidigare besök';
            }
            
            return this.currReg.kennel.visit.last;
        },

        ownerMembership() {
            if (!this.ownerVisit) {
                return 'Nej';
            }
            if (this.ownerVisit.member) {
                return `Ja i ${this.ownerVisit.membership.map((m: any) => m.klubbkortnamn).join(', ')}` 
            }
            return 'Nej';
        }
    },
})
</script>
