<template>
    <div>
        <v-select
            v-model="selected[input.scope]"
            :items="input.values"
            :item-props="getProps"
            item-title="name"
            item-value="value"
            :chips="input.chips"
            :multiple="input.multiple"
            @update:modelValue="limiter()"
        >

        </v-select>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'e-dropdown',
    props: {
        dynamic: {
            type: Object,
            default: null
        },
        dynamicIndex: {
            type: Number,
            default: null
        },
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
    methods: {
        limiter() {
            if(this.input.limit === undefined) return;

            if(this.selected[this.input.scope].length > this.input.limit) {
                this.selected[this.input.scope].pop()
            }
        },

        getProps(item: any) {
            if (!item) {
                return {};
            }

            return {
                title: item.name,
                subtitle: item.props ? item.props.subtitle : ''
            }
        }
    }
});
</script>
