import { 
    useEventStore,
    useRegistrationsStore,
} from '@/stores/store';

export const getEvent = (eventId: string) => {
    const eventStore = useEventStore();
    return eventStore.getEventByID(eventId)
};

export const getRegistration = (regId: string) => {
    const registrationsStore = useRegistrationsStore()
    return registrationsStore.getRegistrationByID(regId);
}
