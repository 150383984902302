import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!

  return (_openBlock(), _createBlock(_component_v_checkbox, {
    modelValue: _ctx.selected[_ctx.input.scope],
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
    class: "my-0",
    label: _ctx.input.value.name,
    "hide-details": true,
    outlined: "",
    color: "info",
    density: "compact"
  }, null, 8, ["modelValue", "label"]))
}