import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, {
    modelValue: _ctx.selected[_ctx.input.scope],
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
    type: "time",
    "hide-details": _ctx.hideDetails,
    variant: "outlined"
  }, null, 8, ["modelValue", "hide-details"]))
}