<template>
    <div
        v-for="breed in breedsPresent"
        :key="breed.id"
    >
        {{ breed.name }}
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TypeRegistration, useBreedStore } from '@/stores/store';

export default defineComponent({
    name: 'EvaluationBreedPreview',
    props: {
        dynamic: {
            type: Object,
            default: null
        },
        dynamicIndex: {
            type: Number,
            default: null
        },
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {},
        },
        input: {
            type: Object,
            default: () => {},
        },
        values: {
            type: Array,
            default: () => [],
        },
        registration: {
            type: Object,
            default: () => {},
        }
    },
    emits: ['setScope'],
    data() {
        return {
            breeds: useBreedStore(),
            breedsPresent: [] as Array<{ id: number, name: string }>
        }
    },
    async created() {
        if (this.selected[this.input.scope]) {
            this.breedsPresent = this.selected[this.input.scope]
            return;
        }

        await this.breeds.init();

        const currentBreeds = await this.breeds.getKennelBreeds(this.registration as TypeRegistration);
        this.breedsPresent = currentBreeds.map((b) => ({
            id: b.id,
            name: b.name
        }));

        this.$emit('setScope', this.input.scope, this.breedsPresent);
    },
});

</script>

