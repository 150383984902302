<template>
    <v-container
        v-if="!isLoading && evaluation"
        class="px-8 py-8 relative"
    >
        <div class="form-head-sticky">
            <div
                v-if="registration && registration.title"
                class="form-head"
            >
                <div class="d-flex flex-column">
                    <div class="text-body-2">
                        <div
                            v-if="event.type !== 'kennelvisit'"
                            class="mb-4"
                        >
                            <div class="d-flex flex-wrap justify-space-between">
                                <span>
                                    Prov-ID:
                                    <strong>#{{ getEventId(event) }}</strong>
                                </span>
                            </div>

                            <div>
                                <span>
                                    Plats:
                                    <strong>{{ getEventTitleInfo(event.title).eventLocation }}</strong>
                                </span>
                            </div>

                            <div>
                                <span>
                                    Kategorier:
                                    <strong>{{ getEventCategoriesString(event.categories) }}</strong>
                                </span>
                            </div>
                        </div>

                        <span class="text-body-1"><strong>{{ registration.title }}</strong></span>
                        <div>
                            <span v-text="event.type === 'kennelvisit' ? 'Uppfödare: ' : 'Ägare: '" />
                            <strong>{{ registration.ownerName }}</strong>

                            <div
                                v-if="registration.class && registration.ts"
                                class="d-flex flex-wrap justify-space-between"
                            >
                                <span>
                                    Klass:
                                    <strong>{{ registration.class }}</strong>
                                </span>
                                <span>
                                    Datum:
                                    <strong>{{ registrationDate }}</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-3 mx-4">
                <v-alert
                    v-model="showError"
                    border="start"
                    position="sticky"
                    closable
                    variant="flat"
                    type="warning"
                    close-label="Close Alert"
                    elevation="3"
                    class="full-width mb-4"
                >
                    <span>
                        De värden som du har fyllt i har sparats!<br>
                        Observera att värden saknas för:
                    </span>
                
                    {{ errorText }}
                </v-alert>
            </div>
        </div>

        <div>
            <v-row
                v-for="scope in evaluation"
                v-show="checkScopeRequirement(scope)"
                :key="scope.scope"
                ripple
                class="cursor-pointer evaluation-section"
            >
                <v-col align="left">
                    <div
                        v-if="!$route.params.extraId && scope.title"
                        id="tooltip"
                        class="section-title"
                    >
                        <div class="title">
                            {{ scope.title }}
                        </div>

                        <v-dialog 
                            v-if="scope.tooltip"
                            v-model="scope.showDialog"
                            class="tooltip" 
                            max-width="600px"
                        >
                            <template #activator="{ props }">
                                <v-btn
                                    icon
                                    class="mb-0"
                                    v-bind="props"
                                    flat
                                >
                                    <v-icon>mdi-information-outline</v-icon>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="headline">
                                    <slot name="title">
                                        {{ scope.title }}
                                    </slot>
                                </v-card-title>

                                <v-card-text
                                    v-for="s in (scope.tooltip).split('\n')"
                                    :key="s"
                                    class="headline"
                                >
                                    <slot name="text">
                                        {{ s }}
                                    </slot>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="info"
                                        @click="scope.showDialog = false"
                                    >
                                        Stäng
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>

                    <evaluation-inputs
                        v-if="scope"
                        ref="evaluation"
                        :inputs="scope.inputs"
                        :registration="registration"
                        :all-changes="evaluationChanges"
                        :values="values"
                        :disable="no_show_local"
                        @change="onChange"
                    />
                </v-col>
            </v-row>
            <v-row v-if="summeriseType">
                <v-col>
                    <component
                        :is="summeriseType"
                        :values="[...values || []]"
                        :changes="[...evaluationChanges || []]"
                        :evaluations="evaluation"
                        :type="summeriseType"
                        @change="onChange"
                    />
                </v-col>
            </v-row>
        </div>

        <v-row class="ma-0 pa-0 pt-8">
            <v-col class="ma-0 pa-0">
                <v-list-item-content>
                    <v-btn
                        color="success"
                        block
                        :loading="isSaving"
                        @click.prevent="saveChanges"
                    >
                        Spara
                    </v-btn>

                    <v-btn
                        style="margin-top: 20px"
                        color="info"
                        block
                        @click="$router.push({ name: 'RegistrationView' })"
                    >
                        Stäng
                    </v-btn>
                </v-list-item-content>
            </v-col>
        </v-row>
    </v-container>
    <div
        v-else
        class="layout-container-fullscreen layout-padding-top"
    >
        <v-progress-circular
            indeterminate
            color="var(--green-3)"
            size="150"
            width="20"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import EvaluationInputs from '@/components/EvaluationInput.vue';

import SummeriseSWKSums from '@/components/data-handlers/SummeriseSWKSums.vue';
import SummeriseChaseTax from '@/components/data-handlers/SummeriseChaseTax.vue';
import SummeriseTJTK from '@/components/data-handlers/SummeriseTJTK.vue';

import { 
    TypeEvent, 
    useEventStore,
    TypeRegistration, 
    useRegistrationsStore,
    useEvaluationsStore,
    TypeEventDateTs
} from '@/stores/store';

import { getEventId, getEventTitleInfo, getEventDate, getEventCategoriesString } from '@/helpers/eventInfoHelper';

import skk from '@/components/shared/translations/skk.json'

import {
    getCurrentJsonnetFile,
    checkScopeRequirements
} from '@/helpers/InputHelpers';

import {
    saveToFirestore,
} from '@/helpers/SaveManager';

export default defineComponent({
    name: 'EvaluationView',
    components: {
        EvaluationInputs,
        'swk': SummeriseSWKSums,
        'chase_tax': SummeriseChaseTax,
        'tjtk_anlagsprov_vildsvin': SummeriseTJTK,
        'tjtk_anlagsprov_falt': SummeriseTJTK,
        'tjtk_shot_water': SummeriseTJTK,
        'tjtk_anlagsprov_skog': SummeriseTJTK,
    },
    data: () => ({
        isLoading: false,
        isSaving: false,
        showdialog: [],
        showModal: false,
        selectedScope: 0,
        rawCompetitor: {},
        evaluation: [] as any,
        values: [] as any,
        categories: {},
        events: useEventStore(),
        registrations: useRegistrationsStore(),
        evaluations: useEvaluationsStore(),
        evaluationChanges: [] as Object[],
        evaluationLoading: false,
        showError: false,
        errorText: '',
        no_show_local: 0,
        compiledFunctions: [] as any[],
        currentType: '',
        summeriseType: null as String|null,
    }),
    computed: {
        event() {
            return this.events.getEventByID(this.$route.params.eventId as string);
        },

        registration() {
            return this.registrations.getRegistrationByID(this.$route.params.regId as string);
        },

        registrationClasses() {
            if (!this.registration.class) {
                return '';
            }

            return this.registration.class
        },

        registrationDate() {
            const locale = 'sv-SE'
            const options = { 
                month: 'short', 
                year: 'numeric', 
                day: 'numeric'
            } as Intl.DateTimeFormatOptions;

            const date = this.registration.ts.toDate();

            if (date.getHours() === 1) {
                return `${date.toLocaleDateString(locale, options)} Heldag`
            }

            const timeAdditions = {
                hour: '2-digit',
                minute: '2-digit'
            } as Intl.DateTimeFormatOptions;

            return date.toLocaleDateString(locale, {
                ...options, 
                ...timeAdditions
            });
        },

        no_show() {
            if (this.evaluations.evaluations.length) {
                const evaluation = this.evaluations.evaluations.find((v: any) => v.id === 'evaluation');
                if (evaluation) {
                    const { no_show } = evaluation;
                    if (no_show) {
                        return no_show.value
                    }
                }
            }
            return 0;
        }
    },
    async mounted() {
        this.isLoading = true;
        this.currentType = this.$route.params.type.toString();
        const eventId = this.$route.params.eventId.toString();
        const regId = this.$route.params.regId.toString();

        // If we dont know type send us back to last page we visited
        if (this.currentType === '') {
            this.$router.back();
        }

        await this.events.init();
        if (!this.registrations.registrations.length) {
            await this.registrations.init(this.event as TypeEvent);
        }

        await this.evaluations.init(this.registration as TypeRegistration);
        this.values = this.getValues();

        const jsonnetFile = getCurrentJsonnetFile(eventId, regId);

        if (!jsonnetFile) {
            this.values = null;
            this.evaluation = null;
            this.no_show_local = this.no_show;
            this.isLoading = false;
            return
        }

        if (this.event.type === 'working_test') {
            this.evaluation = jsonnetFile.evaluations;
        } else {
            this.evaluation = jsonnetFile[this.currentType];
        }


        if (this.event.type === 'chase_tax' && this.currentType === 'registration') {
            this.summeriseType = 'chase_tax'
        }
        if (this.event.type === 'hunting_swk_hunting' && this.currentType === 'moment') {
            this.summeriseType = 'swk';
        }
        if (this.event.type === 'hunting_tjtk_skott_vatten' && this.currentType === 'work_moments') {
            this.summeriseType = 'tjtk_shot_water';
        }
        if (this.event.type === 'hunting_tjtk_anlagsprov_skog' && this.currentType === 'work_moments') {
            this.summeriseType = 'tjtk_anlagsprov_skog';
        }
        if (this.event.type === 'hunting_tjtk_anlagsprov_falt' && this.currentType === 'work_moments') {
            this.summeriseType = 'tjtk_anlagsprov_falt';
        }
        if (this.event.type === 'hunting_tjtk_anlagsprov_vildsvin' && this.currentType === 'work_moments') {
            this.summeriseType = 'tjtk_anlagsprov_vildsvin';
        }

        this.no_show_local = this.no_show;

        this.isLoading = false;
    },
    methods: {
        checkScopeRequirement(block: any) {
            return checkScopeRequirements(block, this.values, this.evaluationChanges)
        },

        onChange(changes: Array<Object>) {
            function newestUniq(a: Array<Object>) {
                const index = {} as any;
                a.forEach((item: any) => {
                    index[item.type] = item
                });
                return Object.values(index) as Array<Object>;
            }

            // Sends an event through so need to check if array before saving
            if (Array.isArray(changes)) {
                changes.forEach((e) => {
                    this.evaluationChanges.push(e);
                });
                this.evaluationChanges = newestUniq(this.evaluationChanges);
            }
        },


        async saveChanges() {
            this.isSaving = true;

            const valid = await saveToFirestore(this.event.id, this.registration.id, this.currentType, this.no_show_local, this.values, this.evaluationChanges);

            this.isSaving = false;

            if (!valid?.valid) {
                this.printMissingInputs(valid)
                return;
            }

            let query = {}

            if (this.event.type === 'working_test') {
                query = { openPreview: 'true' }
            }

            this.$router.push({
                name: 'RegistrationView',
                query
            });
        },

        printMissingInputs(validObject: { valid: Boolean, missing: any[] }) {
            const { valid, missing } = validObject;
            if (valid) {
                return;
            }

            if (missing) {
                const missingTranslated = missing.map((missingInput: any) => 
                    (skk.translations as any)[missingInput.scope] ?? missingInput.scope
                )
                this.errorText = `${missingTranslated.join(',\n')}`;
            } else {
                this.errorText = 'Kunde ej hitta tävlingstyp';
            }

            this.showError = true;
            this.isSaving = false;
        },

        getValues() {
            let value;

            if (this.evaluations.evaluations.length > 0 && this.evaluations.evaluations !== undefined)
            {
                const evaluation = this.evaluations.evaluations.find((e: any) => e.id === this.currentType);

                if (evaluation !== undefined) {
                    const map = new Map(Object.entries(evaluation));
                    value = Array.from(map.values());
                }
            }
            return value;
        },

        getEventId(event: TypeEvent) {
            return getEventId(event);
        },

        getEventTitleInfo(title: string) {
            return getEventTitleInfo(title);
        },

        getEventDate(ts: TypeEventDateTs) {
            return getEventDate(ts);
        },

        getEventCategoriesString(categories: Array<string>) {
            return getEventCategoriesString(categories);
        }
    },
});

</script>

<style lang="less">
.evaluation {
  .__view {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
}

.points-input {
  .v-input__slot::before {
    border-color: transparent !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.section-title {
  border-bottom: 1px solid lighten(#000, 80%);
  display: flex;
  margin-bottom: 16px;
  padding-bottom: 6px;
  .title {
    font-size: 20px;
    font-weight: bold;
    flex-grow: 1;
  }
  .tooltip {
    margin-top: -1px;
  }
}
</style>
