<template>
    <div v-if="input.max">
        <v-textarea
            v-model="selected[input.scope]"
            :hide-details="hideDetails"
            outlined
            :placeholder="input.value ?? `Kommentar`"
            @keyup="countdown"
        >
            <template #details>
                <p 
                    class="text-right text-small"
                    :style="hasError ? 'color: red' : ''"
                >
                    {{ remainingCount }}
                </p>
            </template>
        </v-textarea>
    </div>
    <v-textarea
        v-else
        v-model="selected[input.scope]"
        :hide-details="hideDetails"
        outlined
        :placeholder="input.value ?? 'Kommentar'"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'e-comment',
    props: {
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
    data() {
        return {
            maxCount: (this.input.maxComment ?? this.input.max) ?? 0,
            remainingCount: (this.input.maxComment ?? this.input.max) ?? 0,
            hasError: false,
        }
    },
    methods: {
        countdown() {
            this.remainingCount = this.maxCount - this.selected[this.input.scope].length;
            this.hasError = this.remainingCount <= 0;
            if (this.hasError) {
                // eslint-disable-next-line vue/no-mutating-props
                this.selected[this.input.scope] = 
                    (this.selected[this.input.scope] as string).substring(0, this.maxCount);
                this.remainingCount = 0;
                
            }
        }
    }
})

</script>