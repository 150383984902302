import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_select, {
    modelValue: _ctx.selected[_ctx.input.scope],
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
    items: _ctx.input.value.map((v, i) => ({
            label: v,
            value: i,
        })),
    "item-text": "label",
    "item-value": "value",
    "hide-details": _ctx.hideDetails,
    dense: "",
    outlined: ""
  }, null, 8, ["modelValue", "items", "hide-details"]))
}