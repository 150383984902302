<template>
    <div v-if="breeds.breeds.length">
        <span style="white-space: pre;">{{ getCurrentBreedNames.join('\n') }}</span> <br> <br>

        <div style="border: 2px dotted black; padding: 15px;">
            <v-text-field
                v-model="breedSearch"
                append-icon="mdi-magnify"
                variant="underlined"
                label="Sök hundras"
            />
            <small v-if="!input.single">Lägg till/ta bort hundraser nedanför.</small>
            <small v-else>Välj hundras nedanför</small>

            <div style="max-height: 310px; overflow-y: auto;">
                <div
                    v-for="item in sortedBreeds"
                    :key="item.name"
                >
                    <v-checkbox-btn
                        v-show="breedSearch === '' || lowIncludes(item.name, breedSearch)"
                        :model-value="getCurrentBreedNames.includes(item.name) ? true : false"
                        :label="item.name"
                        hide-details
                        density="compact"
                        color="primary"
                        @click="handleBreed($event, item)"
                    >
                        <template #label>
                            <span class="text-truncate" v-html="highlight(item.name, breedSearch)" />
                        </template>
                    </v-checkbox-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash';
import { defineComponent } from 'vue';
import { TypeRegistration, useBreedStore } from '@/stores/store';


export default defineComponent({
    name: 'EvaluationBreedSelect',
    components: {
    },
    props: {
        dynamic: {
            type: Object,
            default: null
        },
        dynamicIndex: {
            type: Number,
            default: null
        },
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {},
        },
        input: {
            type: Object,
            default: () => {},
        },
        values: {
            type: Array,
            default: () => [],
        },
        registration: {
            type: Object,
            default: () => {},
        }
    },
    emits: ['setScope'],
    data() {
        return {
            breeds: useBreedStore(),
            currentBreeds: [] as Array<{id: number, name: string}>,
            sortedBreeds: [] as Array<{id: number, name: string}>,
            breedSearch: '',
        }
    },
    computed: {
        getCurrentBreedNames() {
            return this.currentBreeds.map((v) => v.name);
        },
    },
    async created() {
        await this.breeds.init();

        this.sortedBreeds = cloneDeep(this.breeds.breeds).sort((a, b) => a.name.localeCompare(b.name));

        if (this.dynamic !== null && this.dynamicIndex !== null) {
            if (!(this.input.scope in this.dynamic[this.dynamicIndex])) {
                this.dynamic[this.dynamicIndex][this.input.scope] = [];
                return;
            }
            this.currentBreeds = this.dynamic[this.dynamicIndex][this.input.scope];
            return;
        }

        if (this.selected[this.input.scope]) {
            this.currentBreeds = this.selected[this.input.scope];
            return;
        }

        if (!this.input.loadBreedsFromKennel) {
            return;
        }

        this.currentBreeds = await this.breeds.getKennelBreeds(this.registration as TypeRegistration);

        this.$emit('setScope', this.input.scope, this.currentBreeds);
    },
    methods: {
        // Search for substrings in a string separated by spaces and commas
        lowIncludes(name: string, search: string) {
            // eslint-disable-next-line security/detect-non-literal-regexp
            const pat = new RegExp(`(?:^|\\s)${search}(?=[a-z ,]|$)`, 'gi');
            return name.search(pat) !== -1;
        },

        // Highlights substrings in a string separated by spaces and commas
        highlight(name: string, search: string) {
            if (!search || search.length < 1) {
                return name;
            }

            // eslint-disable-next-line security/detect-non-literal-regexp
            const pat = new RegExp(`(?:^|\\s)(${search})(?=[a-z ,]|$)`, 'gi');
            // eslint-disable-next-line xss/no-mixed-html
            return name.replace(pat, '<span class="text-decoration-underline">$1</span>');
        },
        handleBreed(event: any, breed: { id: number, name: string }) {
            const exists = this.currentBreeds.findIndex((b: { id: number, name: string }) => b.name === breed.name);
            if (exists !== -1) {
                this.currentBreeds.splice(exists, 1);
            } else {

                if (this.input.single && this.currentBreeds.length > 0) {
                    event.preventDefault();
                    
                    this.currentBreeds = [{
                        id: typeof breed.id === 'number' ? breed.id : parseInt(breed.id, 10),
                        name: breed.name
                    }];

                    if (this.dynamic !== null && this.dynamicIndex !== null) {
                        this.dynamic[this.dynamicIndex][this.input.scope] = this.currentBreeds;
                        return;
                    }

                    this.$emit('setScope', this.input.scope, this.currentBreeds);
                    return;
                }

                this.currentBreeds.push({
                    id: typeof breed.id === 'number' ? breed.id : parseInt(breed.id, 10),
                    name: breed.name
                });
            }
            
            if (this.dynamic !== null && this.dynamicIndex !== null) {
                this.dynamic[this.dynamicIndex][this.input.scope] = this.currentBreeds;
                return;
            }

            this.$emit('setScope', this.input.scope, this.currentBreeds);
        }
    },
})

</script>

<style>

.scroller {
    height: 300px !important;
}

</style>
