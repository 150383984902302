<template>
    <div>
        <v-app-bar
            id="main-navbar"
            density="compact"
            absolute
            color="var(--color-accent)"
            class="text-white"
        >
            <template #prepend>
                <div class="d-flex justify-space-between pr-1">
                    <v-btn
                        icon
                        size="42"
                        class="mr-2"
                        @click="back"
                    >
                        <v-icon
                            size="32"
                            icon="mdi-arrow-left"
                        />
                    </v-btn>
                    <v-btn
                        icon
                        size="42"
                        @click="$router.go(0)"
                    >
                        <v-icon
                            size="32"
                            icon="mdi-refresh"
                        />
                    </v-btn>
                </div>
            </template>

            <v-app-bar-title class="d-flex justify-center align-center ma-0">
                <img
                    v-if="['kk', 'kk_dev', 'kk_test', 'kk_local'].includes(userStore.getEndpoint)"
                    src="@resources/kk-logo-negative.png"
                    alt="KK-logo"
                    height="35"
                    class="mt-1"
                >
                <img
                    v-else
                    src="@resources/skk-start-logo-negative.png"
                    alt="SKK-logo"
                    height="35"
                    class="mt-1"
                >
            </v-app-bar-title>

            <template #append>
                <div class="d-flex justify-end pl-1">
                    <v-btn
                        icon
                        size="42"
                        @click="drawer = true"
                    >
                        <v-icon
                            size="32"
                            icon="mdi-menu"
                        />
                    </v-btn>
                </div>
            </template>
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            location="right"
            temporary
            absolute
        >
            <div class="d-flex flex-column align-center bg-grey-lighten-4 px-4 py-2">
                <v-avatar>
                    <v-icon
                        icon="mdi-account"
                        size="30"
                    />
                </v-avatar>
                <h6 class="text-center text-subtitle-1">
                    {{ getUserName }}
                </h6>
            </div>

            <v-divider />

            <div class="d-flex flex-column pa-4">
                <v-btn
                    block
                    variant="flat"
                    color="info"
                    class="flex-between mb-2"
                    :disabled="events.isSyncing"
                    @click="logout"
                >
                    <span>Logga ut</span>
                    <v-icon icon="mdi-logout-variant" />
                </v-btn>
                <v-btn
                    block
                    variant="tonal"
                    class="flex-between"
                    :disabled="events.isSyncing"
                    @click="events.sync()"
                >
                    <span>Synkronisera</span>
                    <v-icon icon="mdi-cloud-sync" />
                </v-btn>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script lang="ts">
import { 
    useEventStore, 
    useUserStore, 
    useRegistrationsStore, 
    useEvaluationsStore 
} from '@/stores/store';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NavBar',
    data() {
        return {
            drawer: false,
            userStore: useUserStore().getUser(),
            events: useEventStore(),
            registrations: useRegistrationsStore(),
            evaluations: useEvaluationsStore()
        }
    },
    computed: {
        getUserName() {
            return this.userStore.user.name;
        },
        getHeaderTitle() {
            switch (this.userStore.getEndpoint) {
                case 'kk':
                    return 'Kennelkonsulenterna'
                case 'skkdev':
                case 'skklocal':
                    return 'SKK Start (Dev)';
                case 'skktest':
                    return 'SKK Start (Test)';
                case 'skk':
                    return 'SKK Start';
                default:
                    return this.userStore.getEndpoint;
            }
        }
    },
    methods: {
        async back() {
            switch (this.$route.name) {
                case 'kennelvisit_follow_up':
                case 'bloodtracking':
                case 'chase':
                case 'evaluation':
                    this.$router.push({name: 'RegistrationView'});
                    break;
                case 'RegistrationView':
                    this.$router.push({name: 'RegistrationList'});
                    break;
                case 'RegistrationList':
                    this.registrations.destroy();
                    this.$router.push({name: 'EventList'});
                    break;
                default:
                    break;
            }
        },

        logout() {
            if (this.events.isSyncing) {
                return;
            }

            this.evaluations.destroy();
            this.registrations.destroy();
            this.events.destroy();
            this.userStore.destroy();

            this.$router.push({ name: 'auth' });
        }
    }
})
</script>
