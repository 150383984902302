<template>
    <div class="info-container">
        <v-avatar
            color="surface-variant"
            size="100"
            style="margin-bottom: 20px; font-size: 50px;"
        >
            <span v-if="showStartOrder">{{ reg.start_order + 1 }}</span>
        </v-avatar>

        <h1>{{ reg.title }}</h1>

        <div class="info-col-container">
            <v-col 
                v-if="reg"
                align="center" 
                class="info-col" 
            >
                <span class="category-span">PROV-ID</span>
                <h4>#{{ getEventId(event) }}</h4>

                <span class="category-span">PLATS</span>
                <h4>{{ getEventTitleInfo(event.title).eventLocation }}</h4>

                <span class="category-span">DATUM</span>
                <h4
                    style="text-transform: capitalize;"
                >
                    {{ getEventDate(event.ts) }}
                </h4>

                <span class="category-span">KATEGORIER</span>
                <h4>
                    {{ getEventCategoriesString(event.categories) }}
                </h4>
            </v-col>

            <v-col 
                v-if="reg"
                align="center" 
                class="info-col" 
            >
                <span class="category-span">RAS</span>
                <h4>{{ reg.breed }}</h4>

                <div v-if="showClass">
                    <span class="category-span">Klass</span>
                    <h4>{{ reg.class }}</h4>
                </div>

                <span class="category-span">KÖN</span>
                <h4>{{ reg.gender === 'F' ? 'T' : 'H' }}</h4>

                <span class="category-span">ÄGARE / FÖRARE</span>
                <h4>{{ reg.ownerName }}</h4>

                <div v-if="reg.chip">
                    <span class="category-span">CHIPNUMMER</span>
                    <h4>{{ reg.chip }}</h4>
                </div>

                <span class="category-span">REGISTRERINGSNUMMER</span>
                <h4>{{ reg.regnum }}</h4>
            </v-col>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { TypeRegistration, TypeEvent, TypeEventDateTs } from '@/stores/store';
import { getEventId, getEventTitleInfo, getEventDate, getEventCategoriesString } from '@/helpers/eventInfoHelper';

export default defineComponent({
    props: {
        reg: {
            type: Object as PropType<TypeRegistration>,
            default: () => {}
        },
        event: {
            type: Object as PropType<TypeEvent>,
            default: () => {}
        }
    },
    computed: {
        showClass() {
            return this.event.type === 'fa';
        },
        showStartOrder() {
            if (!['hunting_retriever', 'workingtest'].includes(this.event.type)) {
                return false;
            }

            return this.reg.start_order !== null || !Number.isNaN(this.reg.start_order)
        },
        avatarColor() {
            if (this.reg.sentTo) {
                return 'green';
            }

            return 'surface-variant';

        }
    },
    methods: {
        getEventId(event: TypeEvent) {
            return getEventId(event);
        },

        getEventTitleInfo(title: string) {
            return getEventTitleInfo(title);
        },

        getEventDate(ts: TypeEventDateTs) {
            return getEventDate(ts);
        },

        getEventCategoriesString(categories: Array<string>) {
            return getEventCategoriesString(categories);
        }
    }
})
</script>

<style scoped>
.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-col-container {
    display: flex;
    flex-direction: row;
}
</style>