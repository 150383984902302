import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_ctx.dynamic !== null && _ctx.dynamicIndex !== null)
    ? (_openBlock(), _createBlock(_component_v_text_field, {
        key: 0,
        modelValue: _ctx.dynamic[_ctx.dynamicIndex][_ctx.input.scope],
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dynamic[_ctx.dynamicIndex][_ctx.input.scope]) = $event)),
        modelModifiers: { number: true },
        type: "number",
        inputmode: "numeric",
        variant: "outlined",
        "hide-details": _ctx.hideDetails,
        outlined: "",
        placeholder: _ctx.input.title ?? '',
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkInputRules()))
      }, null, 8, ["modelValue", "hide-details", "placeholder"]))
    : (_openBlock(), _createBlock(_component_v_text_field, {
        key: 1,
        modelValue: _ctx.selected[_ctx.input.scope],
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
        modelModifiers: { number: true },
        type: "number",
        inputmode: "numeric",
        variant: "outlined",
        "hide-details": _ctx.hideDetails,
        outlined: "",
        placeholder: _ctx.input.title ?? '',
        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkInputRules()))
      }, null, 8, ["modelValue", "hide-details", "placeholder"]))
}