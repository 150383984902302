<template>
    <v-btn
        :color="isNoShow ? 'error' : getColor(controlPassed)"
        :prepend-icon="getIcon(controlPassed)"
        @click="$emit('click-control')"
    >
        FÖRHANDSKONTROLL
    </v-btn>
    <v-btn
        v-for="(allowedStation) in allowedStations"
        :key="allowedStation.station_number"
        :disabled="isNoShow"
        :color="getColor(hasEval[`station_${allowedStation.station_number}`])"
        :prepend-icon="getIcon(hasEval[`station_${allowedStation.station_number}`])" 
        @click="$emit('click-bed', `station_${allowedStation.station_number}`)"
    >
        {{ allowedStation.station_name }}
    </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    useRegistrationsStore, useUserStore
} from '@/stores/store'

export default defineComponent({
    props: {
        controlPassed: {
            type: Number,
            default: -1
        },
        currReg: {
            type: Object,
            default: () => {}
        },
        currEvent: {
            type: Object,
            default: () => {}
        },
        isNoShow: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'click-bed': null,
        'click-paid': null,
        'click-control': null
    },
    data() {
        return {
            registration: useRegistrationsStore(),
            userStore: useUserStore().getUser(),
            hasEval: {} as any
        }
    },
    computed: {
        allowedStations() {
            const stations = [ ...this.currEvent.stations ];
            return stations.filter((v: any) => 
                v.referee_user_ids.includes(this.userStore.user.id)
            );
        },
    },
    created() {
        this.allowedStations.forEach(async (v) => {
            const done = await this.registration.getHasResult(this.currReg.id, `station_${v.station_number}`)
            // @ts-ignore
            this.hasEval[`station_${v.station_number}`] = done;
        });
    },
    methods: {
        castValue(toCast: number|boolean): number {
            // eslint-disable-next-line no-nested-ternary
            return (typeof toCast === 'boolean') ? (toCast ? 1 : -1) : toCast
        },

        getIcon(val: number|boolean) {
            return this.castValue(val) === 1 ? 'mdi-check-circle-outline' : '';
        },

        getColor(val: number|boolean) {
            switch (this.castValue(val)) {
                case -1:
                    return 'grey'
                case 0:
                    return 'warning'
                case 1:
                    return 'success'
                default:
                    return 'grey'
            }
        }
    },
})
</script>