<template>
    <div>
        <v-text-field
            v-if="dynamic !== null && dynamicIndex !== null"
            v-model="dynamic[dynamicIndex][input.scope]"
            type="date"
            variant="outlined"
        >
            <template
                v-if="input.calculate_age" 
                #details
            >
                <span>{{ calculateAge }}</span>
            </template>
        </v-text-field>
        <v-text-field
            v-else
            v-model="selected[input.scope]"
            type="date"
            :hide-details="hideDetails"
            variant="outlined"
        >
            <template 
                v-if="input.calculate_age"
                #details
            >
                <span>{{ calculateAge }}</span>
            </template>
        </v-text-field>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'e-date',
    props: {
        dynamic: {
            type: Object,
            default: null
        },
        dynamicIndex: {
            type: Number,
            default: null
        },
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
    computed: {
        calculateAge() {
            let input = this.selected[this.input.scope] as any;
            if (this.dynamic !== null && this.dynamicIndex !== null) {
                input = this.dynamic[this.dynamicIndex][this.input.scope]
            }

            if (!input) {
                return '';
            }

            const dateAsMillis = new Date(input).getTime();
            const todayAsMillis = new Date().getTime();

            let diff = todayAsMillis - dateAsMillis;
            let ret = 'Ålder ';

            const week = Math.floor(diff/86400000/7);

            if (week >= 1) {
                ret += week + (week === 1 ? ' vecka ' : ' veckor ');
                diff -= week*86400000*7;
            }

            const day = Math.floor(diff/86400000);

            if (day >= 1) {
                ret += day + (day === 1 ? ' dag' : ' dagar');
            }
            
            if (week < 1 && day < 1) {
                return '';
            }

            return ret;
        }
    },
    created() {
        if (this.dynamic !== null && this.dynamicIndex !== null) {
            if (!(this.input.scope in this.dynamic[this.dynamicIndex])) {
                this.dynamic[this.dynamicIndex][this.input.scope] = ''
            }
        }
    },
});
</script>