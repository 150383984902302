<template>
    <footer>
        <v-card elevation="0" rounded="0">
            <v-row 
                no-gutters 
                class="py-3 px-4 bg-grey-lighten-3"
            >
                <v-col>
                    <h3>Summering</h3>
                </v-col>
            </v-row>
            <v-divider />
            <v-row 
                no-gutters 
                class="py-3 px-4 bg-grey-lighten-5"
            >
                <v-col>
                    Provet avbröts kl.
                </v-col>
                <v-col class="d-flex justify-end font-weight-bold">
                    {{ lastReleaseRecoupled }}
                </v-col>
            </v-row>
            <v-divider />
            <v-row 
                no-gutters 
                class="py-3 px-4 bg-grey-lighten-5"
            >
                <v-col>
                    Inkallningar med pejl
                </v-col>
                <v-col class="d-flex justify-end font-weight-bold">
                    {{ amoutUsedPejl }}
                </v-col>
            </v-row>
            <v-divider />
            <v-row 
                no-gutters 
                class="py-3 px-4 bg-grey-lighten-5"
            >
                <v-col>
                    Hunden lös
                </v-col>
                <v-col class="d-flex justify-end font-weight-bold">
                    {{ howLongDogLose }}
                </v-col>
            </v-row>
        </v-card>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ChaseInputSummation',
    props: {
        evaluations: {
            type: Array,
            required: true,
        }
    },
    computed: {
        chaseEvaluations() {
            const chase = this.evaluations.find((v: any) => v.id === 'chase') as any;

            if (!chase) {
                return null;
            }

            const chaseCopy = {...chase};
            delete chaseCopy.id;
            delete chaseCopy.metadata;
            delete chaseCopy.path;
            delete chaseCopy.done;
            delete chaseCopy.chase_day;

            const chaseValues = Object.values(chaseCopy) as Array<
                {
                    id: number, 
                    scope: string, 
                    results: { [key: string]: any } 
                }
            >;

            if (!chaseValues.length) {
                return null;
            }

            chaseValues.sort((a, b) => a.id > b.id ? 1 : -1);

            return chaseValues;
        },

        amoutUsedPejl() {
            if (!this.chaseEvaluations) {
                return 0;
            }

            const amoutYes = this.chaseEvaluations.map((pRes) => Number(pRes.results?.hunting_pejl === 'yes'));
            return amoutYes.reduce((pR, cR) => pR + cR);
        },

        howLongDogLose() {
            if (!this.chaseEvaluations) {
                return '-'
            }

            const toSecs = (timeString: string) => {
                if (timeString === '' || typeof timeString !== 'string') {
                    return 0;
                }

                const splitTimeString = timeString.split(':');
                const hour = Number(splitTimeString[0]);
                const min = Number(splitTimeString[1]);

                const seconds = (hour * 3600) + (min * 60);
                return seconds;
            }

            const secsToHumanReadable = (seconds: number) => {
                let nSec = seconds;

                const hr = Math.floor(nSec / 3600);
                nSec -=  hr * 3600;
                const min = Math.floor(nSec / 60);

                return `${hr > 1 ? `${hr} timmar` : `${hr} timme`} ${min > 9 ? min : `0${min}`} minuter`
            }

            const releaseAndRecouple = this.chaseEvaluations.map((cE) => 
                Math.abs(toSecs(cE.results?.release_time) - toSecs(cE.results?.leashed))
            );

            const allAdded = releaseAndRecouple.reduce((a, b) => a + b)

            return secsToHumanReadable(allAdded);
        },

        lastReleaseRecoupled() {
            if (!this.chaseEvaluations) {
                return '--:--';
            }

            const chaseCopy = [...this.chaseEvaluations];

            chaseCopy.sort((f, s) => f.id > s.id ? -1 : 1);

            if (chaseCopy.length <= 0) {
                return '--:--';
            }

            const latestLeashed = chaseCopy[0].results?.leashed
            return latestLeashed || '--:--';
        }
    }
})

</script>