<template>
    <div class="compact-wrapper">
        <div
            class="row row-wrapper"
        >
            <p>
                {{ input.name }}
            </p>

            <div
                class="row input-wrapper"
            >
                <v-text-field
                    v-model.number="meters"
                    class="input"
                    type="number"
                    density="compact"
                    hide-details
                    min="0"
                    @input="validateAndSave($event, 'meters')"
                />
                <span>
                    m
                </span>

                <v-text-field
                    v-model.number="minutes"
                    class="input"
                    type="number"
                    density="compact"
                    hide-details
                    min="0"
                    @input="validateAndSave($event, 'minutes')"
                />
                <span>
                    min
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EChaseCompact',
    props: {
        input: {
            type: Object,
            default: () => {}
        },
        selected: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            meters: '0',
            minutes: '0',
        }
    },
    created() {
        this.selected[this.input.scope] = this.selected[this.input.scope] || '0 - 0';

        const [meters, minutes] = this.selected[this.input.scope].split(' - ');

        this.meters = meters;
        this.minutes = minutes;
    },
    methods: {
        validateAndSave(event: Event, type: 'meters' | 'minutes') {
            const input = event.target as HTMLInputElement;
            const nonNumericPattern = /\D/g;

            if (nonNumericPattern.test(input.value) || input.value === '') {
                this[type] = '0';
                input.value = '0';
            }

            this.selected[this.input.scope] = `${this.meters} - ${this.minutes}`;
        }
    }
})
</script>

<style scoped>
    .compact-wrapper {
        display: flex;
        flex-direction: column;

    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    p {
        height: fit-content;
        margin-right: 5px;
    }

    .input-wrapper {
        align-items: end;
        flex-grow: 1;
    }

    .row-wrapper * {
        margin: 5px 10px;
    }

    .row-wrapper .v-input {
        margin-right: 0;
    }

    .row-wrapper span {
        margin-left: 5px;
    }
</style>