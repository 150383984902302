<template>
    <v-btn
        :color="isNoShow ? 'error' : getColor(controlPassed)"
        :prepend-icon="getIcon(controlPassed)"
        @click="$emit('click-control')"
    >
        FÖRHANDSKONTROLL
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.evaluation)"
        :prepend-icon="getIcon(hasEval.evaluation)" 
        @click="$emit('click-bed', 'evaluation')"
    >
        BEDÖMNING
    </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    useRegistrationsStore
} from '@/stores/store';

export default defineComponent({
    props: {
        controlPassed: {
            type: Number,
            default: -1
        },
        currReg: {
            type: Object,
            required: true,
        },
        isNoShow: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'click-bed': null,
        'click-paid': null,
        'click-control': null
    },
    data() {
        return {
            registration: useRegistrationsStore(),
            hasEval: {
                evaluation: -1,
            }
        }
    },
    created() {
        Object.keys(this.hasEval).forEach((type) => {
            this.registration.getHasResult(this.currReg.id, type).then((done: number) => {
                if (Object.hasOwn(this.hasEval, type)) {
                    // @ts-ignore
                    this.hasEval[type] = done;
                    return;
                }
                throw Error('[fa@Actions] Object dosent have eval type');
            }).catch(console.log);
        });
    },
    methods: {
        castValue(toCast: number|boolean): number {
            // eslint-disable-next-line no-nested-ternary
            return (typeof toCast === 'boolean') ? (toCast ? 1 : -1) : toCast
        },

        getIcon(val: number|boolean) {
            return this.castValue(val) === 1 ? 'mdi-check-circle-outline' : '';
        },

        getColor(val: number|boolean) {
            if (this.isNoShow) {
                return 'grey';
            }

            switch (this.castValue(val)) {
                case -1:
                    return 'grey'
                case 0:
                    return 'warning'
                case 1:
                    return 'success'
                default:
                    return 'grey'
            }
        }
    },
})
</script>
