import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_btn, {
      color: _ctx.isNoShow ? 'error' : _ctx.getColor(_ctx.controlPassed),
      "prepend-icon": _ctx.getIcon(_ctx.controlPassed),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-control')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" FÖRHANDSKONTROLL ")
      ]),
      _: 1
    }, 8, ["color", "prepend-icon"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allowedStations, (allowedStation) => {
      return (_openBlock(), _createBlock(_component_v_btn, {
        key: allowedStation.station_number,
        disabled: _ctx.isNoShow,
        color: _ctx.getColor(_ctx.hasEval[`station_${allowedStation.station_number}`]),
        "prepend-icon": _ctx.getIcon(_ctx.hasEval[`station_${allowedStation.station_number}`]),
        onClick: ($event: any) => (_ctx.$emit('click-bed', `station_${allowedStation.station_number}`))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(allowedStation.station_name), 1)
        ]),
        _: 2
      }, 1032, ["disabled", "color", "prepend-icon", "onClick"]))
    }), 128))
  ], 64))
}