import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChaseInputDaySelect = _resolveComponent("ChaseInputDaySelect")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_ChaseInputLetGos = _resolveComponent("ChaseInputLetGos")!
  const _component_ChaseInputSummation = _resolveComponent("ChaseInputSummation")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_ctx.evaluationStore.evaluations.length || _ctx.initialLoad)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_ChaseInputDaySelect, {
            modelValue: _ctx.chaseDate,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chaseDate) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_divider),
          _createVNode(_component_ChaseInputLetGos, {
            evaluations: _ctx.evaluationStore.evaluations,
            event: _ctx.currEvent,
            onUpdateReleases: _ctx.updateReleasesFirebase,
            onSaveReleases: _cache[1] || (_cache[1] = (validity) => _ctx.saveReleasesFirebase(validity))
          }, null, 8, ["evaluations", "event", "onUpdateReleases"])
        ]),
        _createVNode(_component_ChaseInputSummation, {
          evaluations: _ctx.evaluationStore.evaluations,
          style: {"margin-top":"auto"}
        }, null, 8, ["evaluations"])
      ]))
    : (_openBlock(), _createBlock(_component_v_container, {
        key: 1,
        style: {"height":"400px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            class: "fill-height",
            "align-content": "center",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                class: "text-subtitle-1 text-center",
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Laddar data... ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_progress_linear, {
                    modelValue: _ctx.initialLoadProgress,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.initialLoadProgress) = $event)),
                    color: "green-darken-1",
                    rounded: "",
                    height: "6"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}