<template>
    <v-form
        v-model="valid"
        :class="getSiteName"
        @submit.prevent="authenticate"
    >
        <v-container>
            <v-col>
                <v-row class="d-flex justify-center">
                    <img
                        v-if="getSiteName === 'skk'"
                        src="@resources/skk-start-logo.png"
                        alt="SKK-start-logo"
                        height="50"
                        class="skk-logo mb-8 mt-8"
                    >
                    <img
                        v-else-if="getSiteName === 'kk'"
                        src="@resources/kk-logo.png"
                        alt="KK-logo"
                        height="50"
                        class="skk-logo mb-8 mt-8"
                    >
                    <img
                        v-else
                        src="@resources/skk-logo.png"
                        alt="SKK-logo"
                        height="50"
                        class="skk-logo mb-8 mt-8"
                    >
                </v-row>
                <v-row>
                    <v-text-field
                        v-model="email"
                        name="email"
                        :rules="[formRules.required, formRules.email]"
                        label="E-post"
                        variant="solo"
                        density="compact"
                        :hide-details="true"
                    />
                </v-row>
                <v-row>
                    <v-text-field
                        v-model="password"
                        name="password"
                        label="Lösenord"
                        :rules="[formRules.required]"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        variant="solo"
                        density="compact"
                        :hide-details="true"
                        class="mb-4"
                        @click:append="showPassword = !showPassword"
                    />
                </v-row>
                <v-row>
                    <v-select
                        v-model="site"
                        :items="siteOptions[appNamespace]"
                        item-title="name"
                        item-value="url"
                        label="Log in to"
                        :rules="[formRules.required]"
                        variant="outlined"
                        density="compact"
                        :hide-details="true"
                        class="mb-4"
                        @update:modelValue="setSite"
                    />
                </v-row>
                <v-row>
                    <v-btn
                        type="submit"
                        color="success"
                        block
                        :disabled="!valid"
                        append-icon="mdi-login"
                    >
                        Login
                    </v-btn>
                </v-row>
                <v-row>
                    <v-dialog
                        v-model="wipeDialog"
                        contained
                    >
                        <template #activator="{ props }">
                            <v-btn
                                color="warning"
                                variant="text"
                                block
                                v-bind="props"
                            >
                                Rensa lokala databasen
                            </v-btn>
                        </template>

                        <v-card>
                            <v-card-text>
                                Denna funktion ska endast användas på uppmaning av IT-supporten. 
                                Är du säker på att du vill rensa den lokala databasen? (Åtgärden går ej att ångra)
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    color="primary"
                                    @click="wipeDialog = false"
                                >
                                    Avbryt
                                </v-btn>
                                <v-btn
                                    color="warning"
                                    variant="text"
                                    :disabled="!canWipe" 
                                    @click.prevent="wipeDialog = false; wipe()"
                                >
                                    Rensa
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-col>
        </v-container>
    </v-form>
</template>

<script lang="ts">
import { useUserStore } from '@/stores/store';
import { defineComponent } from 'vue';



export default defineComponent({
    name: 'AuthView',
    data() {
        return {
            appNamespace:  (window as any).env.VUE_APP_NAMESPACE || process.env.VUE_APP_NAMESPACE,
            userStore: useUserStore(),
            requestInProgress: false,
            wipeDialog: false,
            valid: false,
            email: '',
            password: '',
            showPassword: false,
            site: undefined,
            siteOptions: {
                dev: [
                    { url: 'https://skk-test.smoothcomp.dev/api/v1', name: 'SKK Test' },
                    { url: 'https://skk-dev.smoothcomp.dev/api/v1', name: 'SKK Dev'},
                    { url: 'https://skk-dev2.smoothcomp.dev/api/v1', name: 'SKK Dev 2'},
                    { url: 'https://skk-dev3.smoothcomp.dev/api/v1', name: 'SKK Dev 3'},
                    { url: 'http://smoothcomp.test/api/v1', name: 'SKK Local'},
                    { url: 'https://kennelkonsulenterna-backend-dev-h2mujzxlyq-ez.a.run.app/api', name: 'Kennelkonsulenterna Dev'},
                    { url: 'https://kennelkonsulenterna-backend-test-h2mujzxlyq-ez.a.run.app/api', name: 'Kennelkonsulenterna Test'},
                    { url: 'http://127.0.0.1:8000/api', name: 'Kennelkonsulenterna Local'},
                ],
                prod: [
                    { url: 'https://skkstart.se/api/v1', name: 'SKK Start' },
                    { url: 'https://kennelkonsulenterna-backend-54qx7indba-ey.a.run.app/api', name: 'Kennelkonsulenterna' },
                    { url: 'https://skk-test.smoothcomp.dev/api/v1', name: 'SKK Test' },
                ]
            } as { [key: string]: Array<{ url: string, name: string}>},
            ep: {
                dev: new Map([
                    ['SKK Test', 'skktest'],
                    ['SKK Dev', 'skkdev'],
                    ['SKK Dev 2', 'skkdev2'],
                    ['SKK Dev 3', 'skkdev3'],
                    ['SKK Local', 'skklocal'],
                    ['Kennelkonsulenterna Dev', 'kk_dev'],
                    ['Kennelkonsulenterna Test', 'kk_test'],
                    ['Kennelkonsulenterna Local', 'kk_local'],
                ]),
                prod: new Map([
                    ['SKK Start', 'skkstart'],
                    ['Kennelkonsulenterna', 'kk'],
                    ['SKK Test', 'skktest'],
                ])
            } as { [key: string]: Map<string, string>},
            formRules: {
                required: (value:any) => !!value || 'Required.',
                email: (value:string) => {
                    const pattern = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail'
                }
            }
        }
    },
    computed: {
        canWipe() {
            return window.indexedDB.databases !== undefined;
        },
        getSiteName() {
            if (this.site === undefined) {
                return '';
            }

            const siteName = this.siteOptions[this.appNamespace].find((v) => v.url === this.site)?.name;

            if (!siteName) {
                return '';
            }

            if (siteName.includes('Kennelkonsulenterna')) {
                return 'kk';
            }

            return 'skk';
        }
    },
    methods: {
        setSite() {
            if (this.appNamespace === 'prod') {
                const siteName = this.siteOptions[this.appNamespace].find((v) => v.url === this.site)?.name;
                
                if (siteName?.toLowerCase().includes('test')) {
                    // eslint-disable-next-line xss/no-location-href-assign
                    window.location.href = 'https://dogapp2-test-54qx7indba-ez.a.run.app/';
                    return;
                }
            }

            if (this.site) {
                localStorage.setItem('skk-base-api', this.site);
            }
        },

        authenticate() {
            if (this.requestInProgress) {
                return;
            }

            this.requestInProgress = true;

            const formData = {
                email: this.email,
                password: this.password
            } as unknown as FormData;

            const siteName = this.siteOptions[this.appNamespace].filter((sites) => sites.url === this.site)[0].name;
            if (!siteName) {
                return;
            }

            const endpoint = this.ep[this.appNamespace].get(siteName);

            this.userStore.auth(formData, endpoint as string).then((response) => {
                if (!response.success) {
                    throw new Error('Could not login');
                }

                this.$router.push({
                    name: 'EventList'
                })
                return true;
            })
                .finally(() => {
                    this.requestInProgress = false;
                })
                .catch(() => {});
        },

        async wipe() {
            const dbs = await window.indexedDB.databases()
            dbs.forEach((db) => { window.indexedDB.deleteDatabase((db as any).name); })

            alert('Rensning av databas avklarad!');
        }
    }
})
</script>

<style lang="less" scoped>
.v-row {
    margin: 10px 0 0 0;
}

.v-form {
    width: 100%;
    margin: 0 !important;
    background-image: url('@resources/skk-start-hero-light.jpg');
    background-size: cover;
    background-position: 57%;
    transition: all 1s ease;

    // &.skk {
    //     background-image: url('@resources/skk-start-hero-light.jpg');
    //     background-position: 67%;
    // }
    &.kk {
        background-image: url('@resources/kk-hero-light.jpg');
        background-position: 67%;
    }
}

.skk-logo {
    transition: all 1s ease;
    animation: fade 1s ease 1;
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>
