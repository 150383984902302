<template>
    <v-row class="my-0 section-title">
        <v-col
            align="lef"
            class="my-0 py-0"
            cols="8"
        >
            <div class="title">
                <slot />
            </div>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'FollowUpTitle',
})
</script>

<style lang="less" scoped>
.section-title {
    padding: 8px;
    border-bottom: 1px solid lighten(#000, 80%);
    display: flex;
    margin-bottom: 16px;
    .title {
        width: 400px;
        font-size: 20px;
        font-weight: bold;
        flex-grow: 1;
        margin-left: 10px;
    }
}
</style>