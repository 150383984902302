<template>
    <a :href="`mailto:${input.email}`">{{ input.email }}</a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EvaluationEmailLink',
    props: {
        input: {
            type: Object,
            default: () => {}
        },
    },
})

</script>