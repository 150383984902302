import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.input.values, (value) => {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
      (_ctx.dynamic !== null && _ctx.dynamicIndex !== null)
        ? (_openBlock(), _createBlock(_component_v_checkbox, {
            key: `${value.value}_dyn`,
            modelValue: _ctx.dynamic[_ctx.dynamicIndex][_ctx.input.scope],
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dynamic[_ctx.dynamicIndex][_ctx.input.scope]) = $event)),
            class: "my-0",
            label: value.name,
            value: value.value,
            "hide-details": true,
            outlined: "",
            color: "info",
            density: "compact"
          }, null, 8, ["modelValue", "label", "value"]))
        : (_openBlock(), _createBlock(_component_v_checkbox, {
            key: value.value,
            modelValue: _ctx.selected[_ctx.input.scope],
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
            class: "my-0",
            label: value.name,
            value: value.value,
            "hide-details": true,
            outlined: "",
            color: "info",
            density: "compact"
          }, null, 8, ["modelValue", "label", "value"]))
    ], 64))
  }), 256))
}