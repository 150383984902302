import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_slider = _resolveComponent("v-slider")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.dynamic !== null && _ctx.dynamicIndex !== null)
      ? (_openBlock(), _createBlock(_component_v_slider, {
          key: 0,
          modelValue: _ctx.dynamic[_ctx.dynamicIndex][_ctx.input.scope],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dynamic[_ctx.dynamicIndex][_ctx.input.scope]) = $event)),
          messages: _ctx.getSliderMessage,
          min: _ctx.kebEnabled ? _ctx.input.min - 1 : _ctx.input.min,
          max: _ctx.input.max,
          step: _ctx.input.step ? _ctx.input.step : 1
        }, {
          append: _withCtx(() => [
            _createElementVNode("nobr", null, _toDisplayString(_ctx.scoreNumber), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "messages", "min", "max", "step"]))
      : (_openBlock(), _createBlock(_component_v_slider, {
          key: 1,
          modelValue: _ctx.selected[_ctx.input.scope],
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
          messages: _ctx.getSliderMessage,
          min: _ctx.kebEnabled ? _ctx.input.min - 1 : _ctx.input.min,
          max: _ctx.input.max,
          step: _ctx.input.step ? _ctx.input.step : 1
        }, {
          append: _withCtx(() => [
            (!_ctx.input.values[_ctx.selected[_ctx.input.scope]]?.custom_box)
              ? (_openBlock(), _createElementBlock("nobr", _hoisted_1, _toDisplayString(_ctx.scoreNumber), 1))
              : (_openBlock(), _createElementBlock("nobr", _hoisted_2, _toDisplayString(_ctx.input.values[_ctx.selected[_ctx.input.scope]].custom_box), 1))
          ]),
          _: 1
        }, 8, ["modelValue", "messages", "min", "max", "step"]))
  ]))
}