import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_kennelvisit_view = _resolveComponent("kennelvisit-view")!
  const _component_view_component = _resolveComponent("view-component")!

  return (_ctx.currEvent.type === 'kennelvisit')
    ? (_openBlock(), _createBlock(_component_kennelvisit_view, {
        key: 0,
        "curr-reg": _ctx.currReg
      }, null, 8, ["curr-reg"]))
    : (_openBlock(), _createBlock(_component_view_component, {
        key: 1,
        reg: _ctx.currReg,
        event: _ctx.currEvent
      }, null, 8, ["reg", "event"]))
}