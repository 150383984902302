import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_radio = _resolveComponent("v-radio")!

  return (_openBlock(), _createBlock(_component_v_radio, {
    "model-value": true,
    color: "info",
    disabled: true
  }, {
    label: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.selectedName), 1)
    ]),
    _: 1
  }))
}