import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_textarea = _resolveComponent("v-textarea")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.input.commentTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.input.commentTitle), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.input.max)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_v_textarea, {
            modelValue: _ctx.vModComment,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vModComment) = $event)),
              _cache[1] || (_cache[1] = (comment) => _ctx.$emit('update:model-value', comment))
            ],
            "hide-details": _ctx.hideDetails,
            outlined: "",
            placeholder: _ctx.input.placeholder ?? `Kommentar`,
            onKeyup: _ctx.countdown
          }, {
            details: _withCtx(() => [
              _createElementVNode("p", {
                class: "text-right text-small",
                style: _normalizeStyle(_ctx.hasError ? 'color: red' : '')
              }, _toDisplayString(_ctx.remainingCount), 5)
            ]),
            _: 1
          }, 8, ["modelValue", "hide-details", "placeholder", "onKeyup"])
        ]))
      : (_openBlock(), _createBlock(_component_v_textarea, {
          key: 2,
          modelValue: _ctx.vModComment,
          "onUpdate:modelValue": [
            _cache[2] || (_cache[2] = ($event: any) => ((_ctx.vModComment) = $event)),
            _cache[3] || (_cache[3] = (comment) => _ctx.$emit('update:model-value', comment))
          ],
          "hide-details": _ctx.hideDetails,
          outlined: "",
          placeholder: _ctx.input.placeholder ?? 'Kommentar'
        }, null, 8, ["modelValue", "hide-details", "placeholder"]))
  ]))
}