<template>
    <div>
        <v-col
            v-for="(v, idx) in input.values"
            :key="idx"
            style="margin-bottom: 5px"
        >
            <v-row style="justify-content: space-between">
                <h4 style="width: 60px">
                    {{ v.name }}
                </h4>
                <v-select
                    v-model="selected[v.scope]"
                    class="cuTextField"
                    :style="`background: ${v.border === 'default' ? 'transparent' : v.border};`"
                    variant="outlined"
                    density="compact"
                    :items="v.items"
                    hide-details
                    outlined
                    @vue:mounted="validateAndSet(v, idx)"
                    @update:model-value="validateAndSet(v, idx)"
                />
                <span style="width: 70px">{{ v.coef }}</span>
                <span
                    style="width: 30px; padding: 5px; text-align: center; border: 1px gray solid"
                    :style="`background: ${v.border === 'default' ? 'transparent' : v.border};`"
                >
                    <span>
                        {{ (parseInt(selected[v.scope]) || 0) * v.coef }}
                    </span>
                </span>
            </v-row>
        </v-col>
        <div style="display: flex; justify-content: flex-end">
            <span style="margin-right: 10px; margin-top: 5px"> Total poäng </span>
            <span style="width: 30px; padding: 5px; text-align: center; border: 1px gray solid">
                {{ summary }}
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EPswm',
    props: {
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {},
        },
        input: {
            type: Object,
            default: () => {},
        },
        values: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            inputNumber: [] as number[],
            totals: [] as number[],
            summary: 0,
        };
    },
    methods: {
        validateAndSet(v: any, idx: number) {
            const selectedValue = Math.max(parseInt(this.selected[v.scope], 10) || 0, 0);

            this.totals[idx] = selectedValue * v.coef;
            this.summary = this.totals.reduce((prev, curr) => curr + prev);
        },
    },
});
</script>

<style lang="less">
.cuTextField {
  max-width: 100px;

  div.v-field {
    padding: 0px !important;
    height: 36px;
  }

  div.v-field__input {
    padding-inline-start: 5px !important;
    padding-inline-end: 0px !important;
  }
}
</style>
