<template>
    <v-col style="margin-bottom: 10px;">
        <v-row style="justify-content: space-between;"> 
            <h4
                v-for="(v, idx) in input.values"
                :key="idx"
            >
                {{ v.name }}
            </h4>
        </v-row>
    </v-col>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'e-header',
    props: {
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
})

</script>