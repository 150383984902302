import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_select, {
      modelValue: _ctx.selected[_ctx.input.scope],
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
        _cache[1] || (_cache[1] = ($event: any) => (_ctx.limiter()))
      ],
      items: _ctx.input.values,
      "item-props": _ctx.getProps,
      "item-title": "name",
      "item-value": "value",
      chips: _ctx.input.chips,
      multiple: _ctx.input.multiple
    }, null, 8, ["modelValue", "items", "item-props", "chips", "multiple"])
  ]))
}