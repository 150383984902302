import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.dynamic !== null && _ctx.dynamicIndex !== null)
      ? (_openBlock(), _createBlock(_component_v_text_field, {
          key: 0,
          modelValue: _ctx.dynamic[_ctx.dynamicIndex][_ctx.input.scope],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dynamic[_ctx.dynamicIndex][_ctx.input.scope]) = $event)),
          type: "date",
          variant: "outlined"
        }, _createSlots({ _: 2 }, [
          (_ctx.input.calculate_age)
            ? {
                name: "details",
                fn: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.calculateAge), 1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["modelValue"]))
      : (_openBlock(), _createBlock(_component_v_text_field, {
          key: 1,
          modelValue: _ctx.selected[_ctx.input.scope],
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
          type: "date",
          "hide-details": _ctx.hideDetails,
          variant: "outlined"
        }, _createSlots({ _: 2 }, [
          (_ctx.input.calculate_age)
            ? {
                name: "details",
                fn: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.calculateAge), 1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["modelValue", "hide-details"]))
  ]))
}