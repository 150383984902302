import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_DynamicScrollerItem = _resolveComponent("DynamicScrollerItem")!
  const _component_DynamicScroller = _resolveComponent("DynamicScroller")!

  return (_openBlock(), _createBlock(_component_DynamicScroller, {
    class: "scroller",
    direction: "horizontal",
    items: _ctx.partsList,
    "min-item-size": 54,
    "key-field": "id"
  }, {
    default: _withCtx(({ item, index, active }) => [
      _createVNode(_component_DynamicScrollerItem, {
        item: item,
        active: active,
        "size-dependencies": [
                    item.id
                ],
        "data-index": index
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass({ 'list-item': true, 'active': item.id === _ctx.trackingState.currentPart})
          }, [
            _createTextVNode(_toDisplayString(item.name) + " ", 1),
            (item.id !== _ctx.partsList.length - 1)
              ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-chevron-right")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _: 2
      }, 1032, ["item", "active", "size-dependencies", "data-index"])
    ]),
    _: 1
  }, 8, ["items"]))
}