import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!

  return (_ctx.dynamic !== null && _ctx.dynamicIndex !== null)
    ? (_openBlock(), _createBlock(_component_v_radio_group, {
        key: 0,
        modelValue: _ctx.dynamic[_ctx.dynamicIndex][_ctx.input.scope],
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dynamic[_ctx.dynamicIndex][_ctx.input.scope]) = $event)),
        style: {"margin-top":"0px !important"},
        "hide-details": _ctx.hideDetails
      }, {
        default: _withCtx(() => [
          (Array.isArray(_ctx.input.value))
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.input.value, (option, value) => {
                return (_openBlock(), _createBlock(_component_v_radio, {
                  key: `${_ctx.input.scope}_${value}`,
                  value: option.value,
                  color: "info"
                }, {
                  label: _withCtx(() => [
                    (option.bold)
                      ? (_openBlock(), _createElementBlock("strong", _hoisted_1, _toDisplayString(option.name), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(option.name), 1))
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(Object.keys(_ctx.input.value), (value) => {
                return (_openBlock(), _createBlock(_component_v_radio, {
                  key: `${_ctx.input.scope}_${value}`,
                  label: _ctx.input.value[value],
                  value: Number(value),
                  color: "info"
                }, {
                  label: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.input.value[value]), 1)
                  ]),
                  _: 2
                }, 1032, ["label", "value"]))
              }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "hide-details"]))
    : (_openBlock(), _createBlock(_component_v_radio_group, {
        key: 1,
        modelValue: _ctx.selected[_ctx.input.scope],
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
        style: {"margin-top":"0px !important"},
        "hide-details": _ctx.hideDetails
      }, {
        default: _withCtx(() => [
          (Array.isArray(_ctx.input.value))
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.input.value, (option, value) => {
                return (_openBlock(), _createBlock(_component_v_radio, {
                  key: `${_ctx.input.scope}_${value}`,
                  value: option.value,
                  color: "info"
                }, {
                  label: _withCtx(() => [
                    (option.bold)
                      ? (_openBlock(), _createElementBlock("strong", _hoisted_3, _toDisplayString(option.name), 1))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          style: _normalizeStyle(option.mark ? `color: ${option.mark};` : '')
                        }, _toDisplayString(option.name), 5))
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(Object.keys(_ctx.input.value), (value) => {
                return (_openBlock(), _createBlock(_component_v_radio, {
                  key: `${_ctx.input.scope}_${value}`,
                  label: _ctx.input.value[value],
                  value: Number(value),
                  color: "info"
                }, {
                  label: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.input.value[value]), 1)
                  ]),
                  _: 2
                }, 1032, ["label", "value"]))
              }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "hide-details"]))
}