<template>
    <div>
        <h3>Summering</h3>
        <div>{{ calculatedValue }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';

function merge(a: Array<any>, b: Array<any>, prop: string) {
    const reduced = a.filter((aitem) => !b.find((bitem) => aitem[prop] === bitem[prop]));
    return reduced.concat(b);
}

export default defineComponent({
    name: 'SummeriseTJTK',
    props: {
        values: { 
            type: Array,
            required: true,
        },
        changes: {
            type: Array,
            required: true,
        },
        evaluations: {
            type: Array,
            required: true,
        },
        type: {
            type: String,
            required: true,
        }
    },
    emits: ['change'],
    computed: {
        scopeWithKoeff() {
            if (!this.evaluations) {
                return {};
            }

            const arr = this.evaluations
                .flatMap((evl: any) => 
                    evl.inputs
                        .filter((inp: any) => inp.koeff)
                        .flatMap((inp: any) => [{scope: inp.scope, koeff: inp.koeff}]));

            const object = arr.reduce((obj, item) => Object.assign(obj, { [item.scope]: item.koeff }), {});
            return object
        },
        
        calculatedValue() {
            const koeffs = this.scopeWithKoeff;
            const merged = merge(cloneDeep(this.values), cloneDeep(this.changes), 'type')

            if (!merged || !koeffs) {
                return 0;
            }

            const invalidTypes = [
                'summary_tjtk_shot_water',
                'summary_tjtk_anlagsprov_falt',
                'summary_tjtk_anlagsprov_skog',
                'summary_tjtk_anlagsprov_vildsvin',
                'date_done',
                'comment',
            ];

            const vals = merged.map((val) => {
                const wrongType = val.type && invalidTypes.some(
                    (type) => val.type.includes(type)
                );

                if (!val.type || wrongType) {
                    return 0;
                }
                const koef = koeffs[val.type];

                val.value = val.value < 0 ? 0 : val.value;
                if (val.type === 'waterwork' && val.value === 5) {
                    val.value = 4;
                }

                return koef ? val.value * koef : val.value;
            }).filter((v) => v >= 0);

            return Math.max(0, vals.reduce((cur, next) => cur + next, 0));
        }
    },
    watch: {
        calculatedValue(newVal) {
            this.emitValue(newVal);
        }
    },
    mounted() {
        this.emitValue(this.calculatedValue);
    },
    methods: {
        emitValue(val: number) {
            this.$emit('change', [{
                type: `summary_${this.type}`,
                value: val,
                value_text: `${val}`,
                comment: '',
                scope: null,
                dynamic: null
            }]);
        }
    }
})

</script>
