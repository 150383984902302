<template>
    <v-checkbox
        v-model="selected[input.scope]"
        class="my-0"
        :label="input.value.name"
        :hide-details="true"
        outlined
        color="info"
        density="compact"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ECheckbox',
    props: {
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {},
        },
        input: {
            type: Object,
            default: () => {},
        },
        values: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['setScope'],
    created() {
        if (this.input.turnoff) {
            this.$watch('selected', () => {
                if (this.selected[this.input.scope]) {
                    this.$emit('setScope', this.input.turnoff, false);
                }
            }, { deep: true })
        }

        if (!this.input.default) {
            return;
        }

        const { scope } = this.input;
        const inputValue = this.values.find((value: any) => value.type === scope) as any;

        if (inputValue && inputValue.value_text !== this.input.default) {
            return;
        }

        this.$emit('setScope', this.input.scope, this.input.default);
    },
});
</script>
