<template>
    <v-btn
        :color="isNoShow ? 'error' : getColor(controlPassed)"
        :prepend-icon="getIcon(controlPassed)"
        @click="$emit('click-control')"
    >
        FÖRHANDSKONTROLL
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.condition)"
        :prepend-icon="getIcon(hasEval.condition)" 
        @click="$emit('click-bed', 'condition')"
    >
        FÖRUTSÄTTNINGAR
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.moment)"
        :prepend-icon="getIcon(hasEval.moment)" 
        @click="$emit('click-bed', 'moment')"
    >
        EGENSKAP/ARBETSMOMENT
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.price)"
        :prepend-icon="getIcon(hasEval.price)" 
        @click="$emit('click-bed', 'price')"
    >
        PRIS
    </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    useRegistrationsStore
} from '@/stores/store'

export default defineComponent({
    props: {
        controlPassed: {
            type: Number,
            default: -1
        },
        currReg: {
            type: Object,
            required: true,
        },
        isNoShow: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'click-bed': null,
        'click-paid': null,
        'click-control': null
    },
    data() {
        return {
            registration: useRegistrationsStore(),
            hasEval: {
                condition: -1,
                moment: -1,
                price: -1
            } as { 
                condition: number,
                moment: number,
                price: number,
                chase: number|undefined
            }
        }
    },
    async created() {
        const keys = Object.keys(this.hasEval);
        const hasRes = keys.map((type) => this.registration.getHasResult(this.currReg.id, type));

        const res = await Promise.all(hasRes);

        const mappedRes = keys.map((key, idx) => ({
            [key]: res[idx]
        }))

        this.hasEval = Object.assign({}, ...mappedRes);
    },
    methods: {
        castValue(toCast: number|boolean): number {
            // eslint-disable-next-line no-nested-ternary
            return (typeof toCast === 'boolean') ? (toCast ? 1 : -1) : toCast
        },

        getIcon(val: number|boolean) {
            return this.castValue(val) === 1 ? 'mdi-check-circle-outline' : '';
        },

        getColor(val: number|boolean) {
            if (this.isNoShow) {
                return 'grey';
            }

            switch (this.castValue(val)) {
                case -1:
                    return 'grey'
                case 0:
                    return 'warning'
                case 1:
                    return 'success'
                default:
                    return 'grey'
            }
        }
    },
})
</script>
