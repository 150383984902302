<template>
    <div>
        <h3>Summering</h3>
        <v-table>
            <thead>
                <tr>
                    <th class="text-left">
                        Namn
                    </th>
                    <th class="text-left">
                        Summa
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Släpptid (min)</td>
                    <td>{{ calculatedValues.run_time }}</td>
                </tr>
                <tr>
                    <td>Egna stånd</td>
                    <td>{{ calculatedValues.run_own_stond }}</td>
                </tr>
                <tr>
                    <td>Egna stötar</td>
                    <td>{{ calculatedValues.run_own_stotar }}</td>
                </tr>
                <tr>
                    <td>Tomstånd</td>
                    <td>{{ calculatedValues.run_empty_stond }}</td>
                </tr>
                <tr>
                    <td>Partners stånd</td>
                    <td>{{ calculatedValues.run_partner_stond }}</td>
                </tr>
                <tr>
                    <td>Partners stötar</td>
                    <td>{{ calculatedValues.run_partner_stotar }}</td>
                </tr>
            </tbody>
        </v-table>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'SummeriseRuns',
    props: {
        values: {
            type: Array,
            required: true
        }
    },
    computed: {
        calculatedValues() {
            if (!this.values.length) {
                return {}
            }

            const addedArr = this.values.reduce((prevRun: any, currRun: any) => ({
                run_time: (prevRun.run_time ?? 0) + (currRun.run_time ?? 0),
                run_own_stond: (prevRun.run_own_stond ?? 0) + (currRun.run_own_stond ?? 0),
                run_own_stotar: (prevRun.run_own_stotar ?? 0) + (currRun.run_own_stotar ?? 0),
                run_empty_stond: (prevRun.run_empty_stond ?? 0) + (currRun.run_empty_stond ?? 0),
                run_partner_stond: (prevRun.run_partner_stond ?? 0) + (currRun.run_partner_stond ?? 0),
                run_partner_stotar: (prevRun.run_partner_stotar ?? 0) + (currRun.run_partner_stotar ?? 0)
            }))

            return addedArr as any
        }
    }
})

</script>