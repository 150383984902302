<template>
    <template v-for="value in input.values">
        <v-checkbox
            v-if="dynamic !== null && dynamicIndex !== null"
            :key="`${value.value}_dyn`"
            v-model="dynamic[dynamicIndex][input.scope]"
            class="my-0"
            :label="value.name"
            :value="value.value"
            :hide-details="true"
            outlined
            color="info"
            density="compact"
        />
        <v-checkbox
            v-else
            :key="value.value"
            v-model="selected[input.scope]"
            class="my-0"
            :label="value.name"
            :value="value.value"
            :hide-details="true"
            outlined
            color="info"
            density="compact"
        />
    </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ECheckboxGroup',
    props: {
        dynamic: {
            type: Object,
            default: null
        },
        dynamicIndex: {
            type: Number,
            default: null
        },
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {},
        },
        input: {
            type: Object,
            default: () => {},
        },
        values: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['setScope'],
    created() {
        if (this.selected[this.input.scope] || 
            ((this.dynamic && this.dynamicIndex) && this.dynamic[this.dynamicIndex][this.input.scope])) {
            return;
        }

        if(this.input.multiple) {
            if (this.dynamic !== null && this.dynamicIndex !== null) {
                this.dynamic[this.dynamicIndex][this.input.scope] = [];
            } else {
                this.$emit('setScope', this.input.scope, []);
            }
        }
    }
});
</script>
