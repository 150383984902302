<template>
    <div v-if="currReg">
        <v-btn
            :color="isNoShow ? 'error' : getColor(controlPassed >= 0 ? controlPassed : hasEval.control)"
            :prepend-icon="getIcon(controlPassed >= 0 ? controlPassed : hasEval.control)"
            @click="$emit('click-control')"
        >
            FÖRHANDSKONTROLL
        </v-btn>
        <v-btn
            :disabled="isNoShow"
            :color="getColor(hasEval.chase)"
            :prepend-icon="getIcon(hasEval.chase)" 
            @click="$router.push({
                name: 'chase'
            })"
        >
            INMATNING AV DREV
        </v-btn>
        <v-btn
            :disabled="isNoShow"
            :color="getColor(hasEval.registration)"
            :prepend-icon="getIcon(hasEval.registration)" 
            @click="$emit('click-bed', 'registration')"
        >
            BEDÖMNING
        </v-btn>
        <v-btn
            :disabled="isNoShow"
            :color="getColor(hasEval.chasestory)"
            :prepend-icon="getIcon(hasEval.chasestory)" 
            @click="$emit('click-bed', 'chasestory')"
        >
            DREVPROVBERÄTTELSE
        </v-btn>
        <v-btn
            :disabled="isNoShow"
            :color="getColor(hasEval.extra)"
            :prepend-icon="getIcon(hasEval.extra)" 
            @click="$emit('click-bed', 'extra')"
        >
            PRIS
        </v-btn>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';

import {
    useRegistrationsStore
} from '@/stores/store'

export default defineComponent({
    props: {
        controlPassed: {
            type: Number,
            default: -1
        },
        currReg: {
            type: Object,
            default: () => {}
        },
        isNoShow: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'click-bed': null,
        'click-paid': null,
        'click-control': null
    },
    setup(props) {
        const registration = reactive(useRegistrationsStore());
        const hasEval = reactive({
            chase: -1,
            registration: -1,
            extra: -1,
            chasestory: -1,
            control: -1,
        }) as any;

        Object.keys(hasEval).forEach((type) => {
            registration.getHasResult(props.currReg.id, type).then((done: number) => {
                if (Object.hasOwn(hasEval, type)) {
                    hasEval[type] = done;
                    return;
                }
                throw Error('[chase@Actions] Object dosent have eval type');
            }).catch(console.log);
        });

        return {
            registration,
            hasEval
        }
    },
    methods: {
        castValue(toCast: number|boolean): number {
            // eslint-disable-next-line no-nested-ternary
            return (typeof toCast === 'boolean') ? (toCast ? 1 : -1) : toCast
        },

        getIcon(val: number|boolean) {
            return this.castValue(val) === 1 ? 'mdi-check-circle-outline' : '';
        },

        getColor(val: number|boolean) {
            if (this.isNoShow) {
                return 'grey';
            }

            switch (this.castValue(val)) {
                case -1:
                    return 'grey'
                case 0:
                    return 'warning'
                case 1:
                    return 'success'
                default:
                    return 'grey'
            }
        }
    }
})
</script>