import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';

import '@mdi/font/css/materialdesignicons.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

import { loadFonts } from './plugins/webfontloader'

import { createPinia } from "pinia";
import { createPiniaFirestore } from "pinia-firestore/packages/pinia-firestore/src/pinia-firestore";

import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { Capacitor } from '@capacitor/core';

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
    },
})

loadFonts()

// @ts-ignore
if (window.env.VUE_APP_NAMESPACE !== 'dev' && Capacitor.getPlatform() === 'web') {
    let sha: string | null = null;
    const checkUpdate = async function () {
        try {
            const res = await axios.get('/hash.txt');

            if (res.data && res.data.length > 0) {
                if (sha && sha !== res.data) {
                    alert('Det finns en ny version tillgänglig, sidan kommer nu laddas om.');
                    window.location.reload();
                } else {
                    sha = res.data;
                }
            }
        } catch (e) {
            // NOTE: ignore errors, we don't care
        }
    };

    setTimeout(checkUpdate, 1000 * 60 * 60);
    checkUpdate();
}

createApp(App)
    .use(router)
    .use(createPinia())
    .use(
        createPiniaFirestore({
          debug: false,
        })
    )
    .use(vuetify)
    .use(VueVirtualScroller)
    .component('VueDatePicker', VueDatePicker)
    .mount('#app');
