<template>
    <div>
        <div v-if="input.commentTitle">
            <h4>{{input.commentTitle}}</h4>
        </div>
        <div v-if="input.max">
            <v-textarea
                v-model="vModComment"
                :hide-details="hideDetails"
                outlined
                :placeholder="input.placeholder ?? `Kommentar`"
                @update:model-value="(comment) => $emit('update:model-value', comment)"
                @keyup="countdown"
                >
                <template #details>
                    <p 
                    class="text-right text-small"
                    :style="hasError ? 'color: red' : ''"
                    >
                    {{ remainingCount }}
                    </p>
                </template>
            </v-textarea>
        </div>
        <v-textarea
            v-else
            v-model="vModComment"
            :hide-details="hideDetails"
            outlined
            :placeholder="input.placeholder ?? 'Kommentar'"
            @update:model-value="(comment) => $emit('update:model-value', comment)"
            />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'CommentInline',
    props: {
        hideDetails: Boolean,
        input: {
            type: Object,
            default: () => {}
        },
        comment: {
            type: String,
            default: ''
        }
    },
    emits: ['update:model-value'],
    data() {
        return {
            maxCount: (this.input.maxComment ?? this.input.max) ?? 0,
            remainingCount: (this.input.maxComment ?? this.input.max) ?? 0,
            hasError: false,
            vModComment: ''
        }
    },
    mounted() {
        this.vModComment = this.comment
    },
    methods: {
        countdown() {
            this.remainingCount = this.maxCount - this.vModComment.length;
            this.hasError = this.remainingCount <= 0;
            if (this.hasError) {
                this.vModComment = this.vModComment.substring(0, this.maxCount);
                this.$emit('update:model-value', this.vModComment);

                this.remainingCount = 0;
            }
        }
    }
})

</script>
