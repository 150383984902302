<template>
    <div>
        <v-dialog 
            v-model="showControl"
        >
            <v-card width="400px">
                <v-card-title align="center">
                    KONTROLLERA
                </v-card-title>
                <v-card-text>
                    <v-col align="center">
                        <h4>
                            Klubbnamn
                        </h4>
                        <span>{{ getClubs }}</span>

                        <h4 class="mt-5">
                            Medlemsnummer
                        </h4>
                        <span>123456789</span>

                        <div 
                            v-for="(value, key) in getContactInfo"
                            :key="key"
                        >
                            <h4 class="mt-5">
                                {{ key }}
                            </h4>
                            <span 
                                v-for="(v, idx) in value"
                                :key="idx"
                            >
                                {{ v }} <br>
                            </span>
                        </div>

                        <h4 class="mt-5">
                            RASER
                        </h4>
                        <span
                            v-for="(breed, idx) in registration.kennel.breeds"
                            :key="idx"
                        >
                            {{ breed.name }}
                        </span>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-col 
                        v-if="!requestControlReason" 
                        class="v-card-button-col"
                    >
                        <v-btn
                            class="mb-4"
                            style="margin: 0px;"
                            variant="flat" 
                            color="green"
                            block 
                            @click="controll('ok')"
                        >
                            <span class="text-white">ALLT ÄR OK</span>
                        </v-btn>
                        <v-btn
                            class="mb-4"
                            style="margin: 0px;"
                            variant="flat" 
                            color="orange" 
                            block 
                            @click="requestControlReason = true" 
                        >
                            <span class="text-white">NÅGOT ÄR FELAKTIGT</span>
                        </v-btn>
                        <v-btn
                            style="margin: 0px;"
                            block 
                            @click="$emit('cancle-control')" 
                        >
                            AVBRYT
                        </v-btn>
                    </v-col>
                    <v-col
                        v-else
                        class="v-card-button-col" 
                        align="center"
                    >
                        <v-btn 
                            style="margin: 0px;"
                            variant="flat"
                            color="green" 
                            block
                            @click="controll('ok')"
                        >
                            <span class="text-white"> ALLT ÄR OK </span>
                        </v-btn>

                        <v-divider style="margin-top: 20px; margin-bottom: 20px;" />

                        <h4>Vad är fel?</h4>
                        <v-textarea 
                            v-model="controlBadReason"
                        />
                        
                        <v-btn
                            class="mb-4"
                            style="margin: 0px;"
                            variant="flat" 
                            color="blue" 
                            :disabled="controlBadReason === ''" 
                            block
                            @click="controll('bad')"
                        >
                            <span class="text-white"> SPARA </span>
                        </v-btn>
                        <v-btn
                            style="margin: 0px;"
                            block
                            @click="$emit('cancle-control')"
                        >
                            AVBRYT
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
import { useRegistrationsStore } from '@/stores/store';
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        showControl: Boolean,
        registration: {
            type: Object,
            default: () => {}
        },
    },
    emits: {
        'control-ok': null,
        'control-bad': null,
        'cancle-control': null
    },
    data() {
        return {
            requestControlReason: false,
            controlBadReason: '',
            registrationStore: useRegistrationsStore(),
        }
    },
    computed: {
        getClubs() {
            if (!this.registration.kennel.owners) {
                return [].join('\n');
            }
            const ow = this.registration.kennel.owners.map((o: any) => o.membership ? o.membership : null)[0] as any;
            const fullname = ow.map((m: any) => m.klubbnamn);

            return fullname.join('\n');
        },

        getContactInfo() {
            if (!this.registration.kennel.owners) {
                return {
                    TELEFONUMMER: [],
                    'E-POST': []
                };
            }
            const tel = this.registration.kennel.owners.map(
                (o: any) => o.tel_number ? o.tel_number : null
            ) as any;

            const mob = this.registration.kennel.owners.map(
                (o: any) => o.mobile_number ? o.mobile_number : null
            ) as any;

            const mail = this.registration.kennel.owners.map(
                (o: any) => o.mail ? o.mail : null
            ) as any;

            tel.push(...mob);

            const filtered_phone = tel.filter((n: any) => n !== null);
            const filtered_mail = mail.filter((n: any) => n !== null);

            return {
                TELEFONUMMER: filtered_phone,
                'E-POST': filtered_mail
            };
        },
    },
    async created() {
        const data = await this.registrationStore.getResultData(this.registration.id, 'control') as any;
        if (!data) {
            return;
        }

        if (data.control_result.controll_status === 'not_ok') {
            this.requestControlReason = true;
            this.controlBadReason = data.control_result.reason;
        }
    },
    methods: {
        controll(result: string) {
            if (result === 'ok') {
                this.controlBadReason = '';
                this.requestControlReason = false;
                this.registrationStore.addResult(this.registration.id, 'control', [{
                    type: 'control_result',
                    controll_status: 'ok'
                }]).then(() => {
                    this.registrationStore.MarkDone(this.registration.id, 'control');
                    return true;
                }).catch(() => {});

                this.$emit('control-ok');
            } else if (result === 'bad') {
                this.registrationStore.addResult(this.registration.id, 'control', [{
                    type: 'control_result',
                    controll_status: 'not_ok',
                    reason: this.controlBadReason,
                }]).then(() => {
                    this.registrationStore.MarkDone(this.registration.id, 'control');
                    return true;
                }).catch(() => {});

                this.$emit('control-bad', this.controlBadReason);
            }

            this.$emit('cancle-control');
        }
    }
})
</script>

<style lang="less" scoped>
.control-box {
    background-color: rgb(32, 60, 94);
    color: white;
    border-radius: 30px;
    margin: 15px 0 15px 0;
    padding: 5px;
}
</style>