<template>
    <div>
        <v-table fixed-header>
            <tbody>
                <tr
                    v-for="(pv) in sortedPartValues"
                    :key="pv.id"
                >
                    <td 
                        v-if="pv.id === 0 || pv.id === (sortedPartValues.length - 1)"
                    >
                        {{ pv.worldTime?.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit'}) }}
                    </td>
                    <td 
                        v-else
                    >
                        <div />
                    </td>
                    <td>{{ pv.name }}</td>
                    <td 
                        style="text-align: right;"
                    >
                        <div>
                            <v-icon 
                                v-if="doneSteps[pv.stateName]"
                                color="green"
                                class="mr-3"
                                icon="mdi-check"
                            />
                            <v-btn 
                                color="info" 
                                icon="mdi-circle-edit-outline"
                                density="compact"
                                variant="plain"
                                @click="openCurrentModal(pv)"
                            />
                        </div>

                        <v-dialog
                            v-model="pv.showModal"
                            style="margin-top: 48px;"
                            contained
                            fullscreen
                            :scrim="false"
                            transition="dialog-bottom-transition"
                        >
                            <v-card>
                                <v-toolbar
                                    dark
                                    color="white"
                                >
                                    <v-toolbar-title style="text-align: center;">
                                        {{ pv.name }}
                                    </v-toolbar-title>

                                    <v-btn 
                                        icon 
                                        @click="closeModal(pv)"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-list>
                                    <v-list-item v-if="pv.id === 0 || pv.id === (sortedPartValues.length - 1)">
                                        <VueDatePicker 
                                            v-model="pv.worldTime" 
                                            :teleport="true"
                                            :clearable="false"
                                        />
                                    </v-list-item>
                                    <v-list-item
                                        v-for="step in getSteps(pv.stateName)"
                                        :key="step.scope"
                                    >
                                        <component
                                            :is="`e-${step.type}`"
                                            :input="step"
                                            :selected="selected"
                                            @change="onChange"
                                        />
                                    </v-list-item>
                                </v-list>
                                
                                <div style="padding: 10px">
                                    <v-row justify="center" align="center">
                                        <v-col cols="6">
                                            <v-btn
                                                block
                                                color="grey"
                                                @click="previousModal(pv)"
                                            >
                                                Föregående
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                block
                                                color="info"
                                                @click="nextModal(pv)"
                                            >
                                                Nästa
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </v-dialog>
                    </td>
                </tr>
            </tbody>
        </v-table>
        <div 
            v-if="trackingState.started && trackingState.finished"
            style="padding: 20px;" 
        >
            <v-tooltip
                :text="isValidToSave ? 'Spara detta resultatet' : 'Du behöver fylla i alla delar innan du kan spara'"
                location="bottom"
            >
                <template #activator="{ props }">
                    <v-btn 
                        v-bind="props"
                        style="margin-top: 10px;"
                        color="success"
                        block
                        tooltip="hej"
                        @click="saveRun"
                    >
                        Spara
                    </v-btn>
                </template>
            </v-tooltip>

            <div class="timing-totals">
                Total tid {{ timingTotals }}
            </div>
        </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { useEvaluationsStore } from '@/stores/store';

import bloodtracking from '@/assets/jsonnet/bloodtracking.jsonnet';

import EvaluationRadio from '@/components/input-types/EvaluationRadio.vue';
import EvaluationCheckbox from '@/components/input-types/EvaluationCheckbox.vue';
import { Timestamp } from 'firebase/firestore';

import EvaluationComment from '@/components/input-types/EvaluationComment.vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
    name: 'TrackingPartList',
    components: {
        VueDatePicker,

        'e-radio': EvaluationRadio,
        'e-checkbox': EvaluationCheckbox,
        'e-comment': EvaluationComment
    },

    props: {
        partList: { 
            type: Array<{ 
                id: number, 
                name: string, 
                stateName: string
            }>,
            required: true,
        },
        partValues: {
            type: Array<{
                id: number,
                name: string,
                stateName: string,
                time: { minutes: number, seconds: number },
                worldTime: Date,
                showModal: boolean,
            }>,
            required: true
        },
        trackingState: {
            type: Object,
            required: true
        },
        registrationClass: {
            type: String,
            required: true
        }
    },

    emits: ['change', 'saveRun', 'setRoundData', 'updatePartValues', 'openPreviousModal', 'openNextModal', 'setCurrentModal'],

    data() {
        return {
            selected: {} as Object,
            comments: {} as Object,
            evaluationChanges: [] as Array<Object>,
            evaluationComments: [] as Array<Object>,
            roundData: [] as Array<{ 
                id: number, 
                name: string, 
                stateName: string,
                time: { minutes: number, seconds: number },
                worldTime: Date 
            }>,
            evaluationStore: useEvaluationsStore(),
        }
    },

    computed: {
        doneSteps() {
            const stateNames = this.partValues.flatMap((v) => v.stateName);
            const doneMap = {};

            stateNames.forEach((stateName) => {
                const steps = this.getSteps(stateName, true);
                // @ts-ignore
                doneMap[stateName] = steps.map((s: any) => !!this.selected[s.scope]).every((v: boolean) => v === true);
            })

            return doneMap;
        },

        isValidToSave() {
            const stateNameList = this.partList.flatMap((v) => v.stateName);   
            return !stateNameList.some(
                // @ts-ignore
                (stateName) => !this.doneSteps[stateName]
            )
        },

        sortedPartValues() {
            const partValuesCopy = [...this.partValues]
            return partValuesCopy.sort((a, b) => a.id - b.id)
        },

        timingTotals() {
            if (this.sortedPartValues.length !== this.partList.length) {
                return '00:00';
            }

            const start = this.sortedPartValues[0]?.worldTime;
            const end = this.sortedPartValues[this.partList.length - 1]?.worldTime;

            if (!start || !end) {
                return '00:00';
            }

            const diffInMs = Math.abs(start.getTime() - end.getTime())

            const diff = [
                diffInMs / 3600000,
                (diffInMs % 3600000) / 60000,
                (diffInMs % 60000) / 1000,
            ].map((v) => Math.floor(v).toString().padStart(2, '0'));

            return diff.join(':');
        }
    },

    mounted() {
        const evals = this.evaluationStore.evaluations.find((evaal: any) => evaal.id === 'steps');
        if (evals) {
            delete evals.id;
            delete evals.metadata;
            delete evals.path;

            Object.values(evals).forEach((element) => {
                if ((element as {
                    id: number, 
                    name: string, 
                    stateName: string,
                    time: { minutes: number, seconds: number },
                    worldTime: Timestamp,
                    showModal: boolean
                })?.stateName) {

                    const roundData = element as {
                        id: number, 
                        name: string, 
                        stateName: string,
                        time: { minutes: number, seconds: number },
                        worldTime: Timestamp,
                        showModal: boolean
                    }

                    const convertedWorldTime = roundData.worldTime.toDate();

                    const rd = {
                        id: roundData.id, 
                        name: roundData.name, 
                        stateName: roundData.stateName,
                        time: roundData.time,
                        worldTime: convertedWorldTime,
                        showModal: roundData.showModal
                    };

                    this.roundData.push(rd);
                    return;
                }

                this.setValue(element)
            });

            if (this.roundData.length) {
                this.$emit('setRoundData', this.roundData);
            }
        }
    },

    methods: {
        saveRun() {
            if (!this.isValidToSave) {
                return;
            }

            this.$emit('saveRun');
        },

        openCurrentModal(pv:
            {
                id: number,
                name: string,
                stateName: string,
                time: { minutes: number, seconds: number },
                worldTime: Date,
                showModal: boolean,
            }
        ) {
            pv.showModal = true;
            this.$emit('setCurrentModal', pv.id);
        },

        closeModal(pv: 
            {
                id: number,
                name: string,
                stateName: string,
                time: { minutes: number, seconds: number },
                worldTime: Date,
                showModal: boolean,
            }
        ) {
            pv.showModal = false;
            this.$emit('updatePartValues');
        },

        previousModal(pv: 
            {
                id: number,
                name: string,
                stateName: string,
                time: { minutes: number, seconds: number },
                worldTime: Date,
                showModal: boolean,
            }
        ) {
            this.closeModal(pv);
            this.$emit('openPreviousModal');
        },

        nextModal(pv: 
            {
                id: number,
                name: string,
                stateName: string,
                time: { minutes: number, seconds: number },
                worldTime: Date,
                showModal: boolean,
            }
        ) {
            this.closeModal(pv);
            this.$emit('openNextModal');
        },

        getSteps(stateName: string, onlyMandatorys?: boolean) {
            const currentStep = bloodtracking.steps[this.registrationClass].find(
                (s: { state: string }) => s.state === stateName).evaluation;

            return onlyMandatorys ? currentStep.inputs.filter((i: any) => i.mandatory === true) : currentStep.inputs;
        },


        setValue (input: any) {
            // @ts-ignore
            this.selected[input.type] = input.value;
        },


        getValueText(type: string, value: any){
            
            if (typeof value === 'boolean') {
                return value ? 'yes' : 'no';
            }

            if (typeof value === 'string') {
                return value;
            }

            return `${value}`;
        },

        onChange() {
            function newestUniq(a: Array<Object>) {
                const index = {} as any;
                // @ts-ignore
                a.forEach((item: any) => {
                    index[item.type] = item
                });
                return Object.values(index) as Array<Object>;
            }

            if (this.selected) {
                const entries = Object.entries(this.selected)
                const mappedEntires = entries.flatMap((entry: [string, any]) => ({
                    type: entry[0],
                    value: entry[1],
                    comment: '',
                    value_text: this.getValueText(entry[0], entry[1]),
                    scope: null,
                }));

                this.evaluationChanges = mappedEntires;
                this.evaluationChanges = newestUniq(this.evaluationChanges);
            }

            this.$emit('change', this.evaluationChanges)
        },
    },
})

</script>

<style>

.timing-totals {
    margin-top: 10px;
    color: darkslategrey;
}

</style>