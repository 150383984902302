<template>
    <kennelvisit-view
        v-if="currEvent.type === 'kennelvisit'"
        :curr-reg="currReg"
    />
    <view-component
        v-else
        :reg="currReg"
        :event="currEvent"
    />
</template>

<script lang="ts">
import {
    TypeEvent, 
    useEventStore, 
    TypeRegistration, 
    useRegistrationsStore 
} from '@/stores/store';

import { defineComponent } from 'vue'
import { useRoute } from 'vue-router';

// Views
import ViewComponent from '@/components/registration/ViewComponent.vue';
import KennelvisitView from '@/components/registration/kennelvisit/View.vue';

export default defineComponent({
    name: 'RegistrationInfo',
    components: {
        ViewComponent,
        KennelvisitView
    },
    props: {
        currentEvent: { type: Object, default: () => {} },
        currentRegistration: { type: Object, default: () => {} }
    },
    emits: ['update:currentEvent', 'update:currentRegistration'],
    async setup () {
        const route = useRoute();
        const events = useEventStore();
        const registrations = useRegistrationsStore();
        
        let currEvent = {} as TypeEvent;
        let currReg = {} as TypeRegistration;

        if (events.events.length === 0 ||
            registrations.registrations.length === 0)
        {
            await events.init();
            await registrations.init(events.getEventByID(route.params.eventId as string));
        }
        
        currEvent = events.getEventByID(route.params.eventId as string);
        currReg = registrations.getRegistrationByID(route.params.regId as string);

        if (currEvent.type === 'kennelvisit') {
            currReg = await registrations.getKennelData(currReg);
        }

        return {
            currEvent,
            currReg,
        }
    },

    created() {
        this.$emit('update:currentEvent', this.currEvent);
        this.$emit('update:currentRegistration', this.currReg);
    }
})
</script>

<style lang="less">
.category-span {
    color: rgb(159,159,159);
}

.info-col {
    h4 {
        margin-bottom: 10px;
    }
}
</style>
